import Card from "../../components/Card";
import styles from "./Modaly.module.sass";
import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import {
  reklamacieExtDoklady,
  reklamacieExtSuroviny,
  zoznamObjednanychPoloziekExtUrl,
} from "../../features/urlHelper";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { errorHandleDialog } from "./../../features/errorDialog";
import Dropzone from "../../components/DropZone";
import ModalVyberZoZoznamu from "../../components/Modaly/ModalVyberZoZoznamu";
import moment from "moment";
import Select from "react-select";
import useDarkMode from "@fisch0920/use-dark-mode";
const App = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const darkMode = useDarkMode(false);
  const [loading, setLoading] = useState(true);

  const [width, setWidth] = useState(0);

  const [row, setRow] = useState({});
  const [mnozstvo, setMnozstvo] = useState(0);
  const [komentar, setKomentar] = useState("");
  const [myFiles, setMyFiles] = useState([]);

  //modal vyber zoznamu
  const [typ, setTyp] = useState("");
  const [idField, setIdField] = useState("");
  const [visible, setVisible] = useState(false);
  const [visibleDoklady, setVisibleDoklady] = useState(false);
  const [data, setData] = useState([]);
  const [valuePolozka, setValuePolozka] = useState({});
  const [valueDoklad, setValueDoklad] = useState({});
  const [reklam_polozky, setReklam_polozky] = useState([
    { mnozstvo: 0, typ_rekl: null, datum: new Date().Date },
  ]);
  const options = [
    { label: "Nižší množství", value: "0" },
    { label: "Kvalita", value: "1" },
    { label: "Vyšší množství", value: "2" },
  ]; //0=nizsi mnozstvi, 1=kvalita, 2=vyssi mnozstvi

  const componentRef = useRef(null);

  useEffect(() => {
    // Získanie šírky komponentu pri načítaní komponentu
    if (componentRef.current) {
      setWidth(componentRef.current.getBoundingClientRect().width);
    }

    // Voliteľne: Aktualizácia šírky pri zmene veľkosti okna
    const handleResize = () => {
      if (componentRef.current) {
        setWidth(componentRef.current.getBoundingClientRect().width);
      }
    };

    window.addEventListener('resize', handleResize);

    // Odstránenie event listeneru pri odpojení komponentu
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function dajFieldPolozky(fields) {
    var retazec = "";
    fields.map((field) => {
      if (valuePolozka[field] !== undefined)
        retazec = retazec + valuePolozka[field] + " ";
    });
    return retazec;
  }

  function dajFieldDokladu(fields) {
    var retazec = "";
    fields.map((field) => {
      if (valueDoklad[field] !== undefined)
        if (field === "datum") {
          retazec = retazec + moment(valueDoklad[field]).format("DD.MM.YYYY");
        } else {
          retazec = retazec + valueDoklad[field] + " ";
        }
    });
    return retazec;
  }

  async function nacitajSuroviny() {
    await axios
      .post(
        reklamacieExtSuroviny,
        {
          ico:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        console.log(response);
        setIdField("znacka");
        var newData = response.data.map((x) => {
          return { ...x, nadpis: x.text, label: x.znacka };
        });
        console.log(newData);
        setData(newData);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function nacitajDoklady() {
    if (valuePolozka.znacka === undefined) {
      swal("Chyba!", "Nejprve musí být vybrána položka.", "error");
      return;
    }
    await axios
      .post(
        reklamacieExtDoklady,
        {
          znacka: valuePolozka.znacka,
          ico:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        setIdField("dl_vodica");
        var newData = response.data.map((x) => {
          return {
            ...x,
            nadpis: x.dl_vodica,
            label: moment(x.datum).format("DD.MM.YYYY"),
          };
        });
        console.log(newData);
        setData(newData);
        setVisibleDoklady(true);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  function dajVysku(height) {
    if (window.innerWidth > 767) {
      return height - 178;
    } else {
      return height - 130;
    }
  }

  function getWidth(width) {
    if (width > 2500) {
      return width - 408;
    } else if (width < 2500 && width > 767) {
      return width - 164;
    } else {
      return width - 24;
    }
  }


  return (
    <Card>
      <div style={{ height: dajVysku(window.innerHeight) }}>
        <div className={styles.nadpis_dokoncit}>
          <div className={styles.nadpis}>Nová reklamace</div>
          <div className={styles.dokoncit}>Dokončit</div>
        </div>
        <div
          style={{ height: dajVysku(window.innerHeight) - 30 }}
          className={styles.main}
        >
          <div className={styles.left}>
            <div className={styles.polozka}>
              <div className={styles.input_nadpis_1}>
                <div>Položka</div>
                <div className={styles.nazov}>
                  <div className={styles.nazov_text}>
                    {dajFieldPolozky(["text", "znacka"])}
                  </div>
                  <div
                    onClick={() => {
                      nacitajSuroviny();
                      setVisible(true);
                    }}
                    className={styles.nazov_lupa}
                  >
                    <i class="fa fa-search"></i>
                  </div>
                </div>
              </div>
              <div className={styles.input_nadpis_2}>
                <div>Měrná jednotka</div>
                <div className={styles.nazov}>{dajFieldPolozky(["mj"])}</div>
              </div>
              <div className={styles.input_nadpis_2}>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className={styles.doklad}>
              <div className={styles.input_nadpis_1}>
                <div>Doklad</div>
                <div className={styles.nazov}>
                  <div className={styles.nazov_text}>
                    {dajFieldDokladu(["dl_vodica", "datum"])}
                  </div>
                  <div
                    onClick={() => {
                      nacitajDoklady();
                    }}
                    className={styles.nazov_lupa}
                  >
                    <i class="fa fa-search"></i>
                  </div>
                </div>
              </div>
              <div className={styles.input_nadpis_2}>
                <div>Dle dokladu přijato</div>
                <div className={styles.nazov}>
                  {dajFieldDokladu(["mnozstvo"])}
                </div>
              </div>
              <div className={styles.input_nadpis_2}>
                <div>Již reklamováno</div>
                <div className={styles.nazov}>
                  {dajFieldPolozky(["reklamovane"])}
                </div>
              </div>
            </div>
            <div className={styles.koment}>
              <div>Komentář</div>
              <textarea className={styles.textarea}></textarea>
            </div>
            <div className={styles.fotky}>
              <div>Fotky reklamované položky</div>
              <Dropzone
                text={"Vložte fotky"}
                myFiles={myFiles}
                setMyFiles={setMyFiles}
              />
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.zoznam_mnozstiev}>
              {reklam_polozky.map((polozka) => {
                return (
                  <div className={styles.doklad_add}>
                    <div className={styles.doklad_layout}>
                      <div className={styles.input_nadpis_3}>
                        <div>Reklamované množství</div>
                        <input
                          ref={componentRef}
                          pattern="[0-9]+([\.][0-9]{1,2})?"
                          type="number"
                          value={polozka.mnozstvo}
                          className={styles.nazov}
                        ></input>
                      </div>
                      <div className={styles.input_nadpis_3}>
                        <div>Typ reklamace</div>
                        <div className={styles.select_area}>
                          <Select
                            //name={params_.id}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: "#FAEECB ",
                                primary50: "#FAEECB ",
                                primary: "#F1B600",
                              },
                            })}
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                borderRadius: 8,
                                borderWidth: 2,
                                width: width,
                                backgroundColor: !darkMode.value
                                  ? "white"
                                  : "#262b30",
                              }),
                              option: (base, state) => ({
                                ...base,
                                width: "100%",
                                borderRadius: 8,
                                color: "#6F767E",
                              }),
                              singleValue: (base, state) => ({
                                ...base,
                                width: "100%",
                                borderRadius: 3,
                                color: "#82878c",
                              }),
                              menu: (base, state) => ({
                                ...base,
                                width: "100%",
                                borderRadius: 0,
                                marginTop: 0,
                                backgroundColor: !darkMode.value
                                  ? "white"
                                  : "#262b30",
                              }),
                            }}
                            className={styles.select}
                            value={polozka.typ_rekl}
                            isClearable={false}
                            isSearchable={false}
                            options={options}
                          />
                        </div>
                      </div>
                      <div className={styles.input_nadpis_3}>
                        <div>Datum</div>
                        <div className={styles.nazov}>{polozka.datum}</div>
                      </div>
                    </div>
                    <div className={styles.input_icon}>
                      <div className={styles.icon}>
                        <i class="fa fa-trash-o"></i>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={styles.pridat_do_zoznamu}>
              <div
                onClick={() => {
                  setReklam_polozky([
                    ...reklam_polozky,
                    {
                      mnozstvo: 0,
                      typ_rekl: null,
                      datum: new Date().Date,
                    },
                  ]);
                }}
                className={styles.pridat}
              >
                <i class="fa fa-plus"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalVyberZoZoznamu
        visible={visible}
        setVisible={setVisible}
        data={data}
        typ={""}
        idField={idField}
        value={valuePolozka}
        setValue={setValuePolozka}
      ></ModalVyberZoZoznamu>
      <ModalVyberZoZoznamu
        visible={visibleDoklady}
        setVisible={setVisibleDoklady}
        data={data}
        typ={""}
        idField={idField}
        value={valueDoklad}
        setValue={setValueDoklad}
      ></ModalVyberZoZoznamu>
    </Card>
  );
};
export default App;
