import React, { useEffect, useState, useRef } from "react";
import Icon from "../../Icon";
import Modal from "../../Modal";
import styles from "./Modal.module.sass";
import {
  DataGridPremium,
  LicenseInfo,
  csCZ,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-premium";
import { LocalizationProvider, csCZ as csCz2 } from "@mui/x-date-pickers-pro";
//import {  } from "@mui/x-license-premium";
import { getLicensePremium } from "../../../features/License";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "dayjs/locale/cs";
import "moment/locale/cs";

import useDarkMode from "@fisch0920/use-dark-mode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import swal from "sweetalert";
import LoadingOverlay from "react-loading-overlay-nextgen";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { setHours } from "date-fns";
import { areArraysEqual } from "../../../features/areArraysEqual";
import { modal } from "../redux/modalSlice";

const App = ({
  className,
  visible,
  setVisible,
  data,
  objednat_data,
  //objednat,
  setIsActive,
  setLoaderText,
  hlavicka,
  nacitaj_data,
  vynulovat_kosik,
  aktualizuj_kosik,
  layoutText,
  setLayoutText,
  layoutActive,
  setLayoutActive,
  datum_presun,
  setDatum_presun,
  zapis_presun,
  rowSelectionModel,
  setRowSelectionModel,
  dataObjednavky,
  buttony,
  nacitaj_plozky_na_presun,
  typ_objednavky
}) => {
  LicenseInfo.setLicenseKey(getLicensePremium());

  const darkMode = useDarkMode(false);
  const elementRef = useRef(null);

  var today = new Date();
  var datumOd = today.setHours(0, 0, 0);

  var tempDatumDo = new Date(today);
  var pridaneDni =  (typ_objednavky === "O" ? 42 : 28) + (7 - tempDatumDo.getDay());
  var datumDo = new Date(today);
  datumDo.setDate(today.getDate() + pridaneDni);

  const [datumBuducehoZapisu, setDatumBuducehoZapisu] = React.useState(
    moment(new Date().setHours(0, 0, 0))
  );

  const darkTheme = createTheme({
    palette: {
      primary: { main: "#F1B600" },
      mode: darkMode.value ? "dark" : "light",
    },
  });
  const stylingTable = {
    border: 0,
    paddingTop: 0,
    padding: 0,
    margin: 0,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "& .MuiDataGrid-cellContent": {
      fontSize: 11,
    },
    letterSpacing: "normal",
    width: window.innerWidth > 760 ? 650 : window.innerWidth - 130,
    height: 530,
    display: "flex",
    justifyContent: "center",
    alighnItems: "center",
    color: darkMode.value ? "white" : "#666d74",
  };

  const stylingRangePicker = {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& .MuiInputBase-input": {
      color: "#82878c",
      fontFamily: "Inter, sans-serif",
      height: 7,
      display: "flex",
      alighnItems: "center",
      justifyContent: "center",
      fontWeight: 400,
      fontSize: 15,
      backgroundColor: !darkMode.value ? "white" : "#262b30",
      borderRadius: 2,
    },

    "& .MuiTypography-root, MuiFormLabel-root": {
      color: "#82878c",
    },
    "& .separator-true": {
      height: 30,
      borderBottom: `3px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#989899",
    },
    "& .MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled": {
      WebkitTextFillColor: "#989899",
    },
    width: "60%",
    display: "flex",
    justifyContent: "center",
    alighnItems: "center",
  };

  const stylingPicker = {
    ...stylingRangePicker,
    width: "40%",
  };

  useEffect(() => {
    if (document.getElementsByName("ico_title")[0] !== undefined) {
      document.getElementsByName("ico_title")[0].click();
    }
  }, [visible]);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#F1B600" },
      },
    },
    csCZ
  );

  let columns = [
    {
      field: "nazov",
      headerName: "Položky",
      headerAlign: "start",
      width: (window.innerWidth > 760 ? 580 : window.innerWidth - 70) * 0.6,
      renderCell: (params) => (
        <>
          <div className={styles.nazov}>
            <div className={styles.avatar2}>
              <img src={"/images/content/bageta.png"} alt="Avatar" />
            </div>
            <div className={styles.nazov_kod}>
              <div>{params.row.nazov}</div>
              <div className={styles.znacka}>
                <div className={styles.znackaText}>{params.row.znacka}</div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      field: "mnozstvo",
      headerName: "Množsví",
      headerAlign: "center",
      type: "number",
      width: (window.innerWidth > 760 ? 580 : window.innerWidth - 70) * 0.3,
      renderCell: (params) => {
        const valueFormatted = Number(params.value);

        const value = valueFormatted
          .toFixed(params.row.des_mista)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");
        return <>{value}</>;
      },
    },
    {
      field: "mj",
      headerName: "MJ",
      headerAlign: "center",
      width: (window.innerWidth > 760 ? 580 : window.innerWidth - 70) * 0.1,
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 50,
    },
  ];

  function vyber_stop_obj_znacky(datum) {
    let znacky = [];
    for (var row_ of dataObjednavky) {
      for (var bunka of row_.stlpce) {
        if (new Date(bunka.datum).getTime() === datum.setHours(0, 0, 0, 0)) {
          if (bunka.stop_obj) {
            znacky.push(bunka.znacka.trim());
          }
        }
        continue;
      }
    }
    return znacky;
  }

  function back() {
    setVisible(false);
  }

  function validnyDatum() {
    return moment(new Date(datum_presun)).format("D.M.YYYY");
  }

  function denVTydnu() {
    moment.locale("cs");
    let dayOfWeek = moment(new Date(datum_presun)).format("dddd");
    return dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
  }

  function text_zapis_kopije(datum, existuju_zaznamy_v_kosiku) {
    if (existuju_zaznamy_v_kosiku) {
      return (
        "<div> Na tento den už máte v košíku nějaké položky. </div>" +
        "<div> Chcete sloučit položky z objednávky na " +
        validnyDatum() + "</div>" +
        " s položkami v košíku na " +
        moment(datumBuducehoZapisu._d, "ddd MMM DD YYYY HH:mm:ss").format(
          "D.M.YYYY" + "?"
        )
      );
    } else {
      return (
        "Chcete přesunout vybrané položky do košíku na " +
        moment(datum, "ddd MMM DD YYYY HH:mm:ss").format("D.M.YYYY") +
        "?"
      );
    }
  }

  function existuju_zaznamy_v_kosiku(datum) {
    // var polozky = data
    //   .map(function (objekt) {
    //     if (rowSelectionModel.includes(objekt.id)) {
    //       return objekt.znacka.trim();
    //     }
    //   })
    //   .filter((x) => x !== undefined);

    for (const riadok of dataObjednavky) {
      //if (polozky.includes(riadok.znacka.trim())) {
      for (const bunka of riadok.stlpce) {
        if (datum.setHours(0, 0, 0, 0) === new Date(bunka.datum).getTime()) {
          if (bunka.bunky[0].mnozstvo !== undefined) {
            return true;
          }
        }
      }
      //}
    }
    return false;
  }

  return (
    <>
      <Modal
        widthModal={window.innerWidth > 780 ? 780 : window.innerWidth}
        outerClassName={styles.outerZaznamProblemu}
        visible={visible}
      >
        <LoadingOverlay
          active={layoutActive}
          className={styles.LoadingOverlay}
          spinner
          text={layoutText}
        >
          <div style={{ width: "100%" }}>
            <div name={"ico_title"} className={styles.ico_Title}>
              <div className={styles.btn}></div>
              <div>
                Objednávka z {validnyDatum()} ({denVTydnu()})
              </div>
              <button
                className={styles.btn}
                onClick={() => {
                  setVisible(false);
                }}
              >
                <Icon name="close" size="20" />
              </button>
            </div>
            <div
              style={{
                height: 580,
              }}
              className={styles.content}
              ref={elementRef}
              id="element"
            >
              <div className={styles.content_l}>
                <div className={styles.buttony_nadpis}>Datum závozu</div>
                <div className={styles.content_l_in}>
                  {buttony.map((button) => {
                    return (
                      <div
                        onClick={() => {
                          var datum = new Date(button.datum);
                          nacitaj_plozky_na_presun(datum);
                          setDatum_presun(datum);
                        }}
                        className={
                          button.isActive ? styles.buttonA : styles.buttonP
                        }
                      >
                        {button.title}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={styles.content_r}>
                <ThemeProvider theme={darkTheme}>
                  <DataGridPremium
                    sx={stylingTable}
                    localeText={{
                      ...csCZ.components.MuiDataGrid.defaultProps.localeText,
                    }}
                    checkboxSelection
                    hideFooter
                    disableColumnPinning
                    disableColumnMenu
                    rowHeight={40}
                    columnHeaderHeight={50}
                    rows={data}
                    columns={columns}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      //console.log(details)
                      const znacky_stop_obj = vyber_stop_obj_znacky(
                        new Date(datumBuducehoZapisu._d)
                      );

                      var polozky = data.filter(function (objekt) {
                        return (
                          newRowSelectionModel.includes(objekt.id) &&
                          !znacky_stop_obj.includes(objekt.znacka.trim())
                        );
                      });
                      var model = [];
                      polozky.map((polozka) => {
                        model.push(polozka.id);
                      });
                      // console.log(model)
                      // console.log(newRowSelectionModel)
                      if (!areArraysEqual(model, newRowSelectionModel)) {
                        swal(
                          "Pozor!",
                          "Některé z položek mají objednání blokováno. Nelze je přidat do přesunu.",
                          "info"
                        );
                      }
                      setRowSelectionModel(model);
                    }}
                    rowSelectionModel={rowSelectionModel}
                  />
                </ThemeProvider>
                <div className={styles.datePicker}>
                  <div>Přesun položek na den</div>
                  <ThemeProvider theme={darkTheme}>
                    <LocalizationProvider
                      adapterLocale="cs"
                      localeText={
                        csCz2.components.MuiLocalizationProvider.defaultProps
                          .localeText
                      }
                      dateAdapter={AdapterMoment}
                    >
                      <DatePicker
                        value={datumBuducehoZapisu}
                        onChange={(param, e) => {
                          console.log(param._d);
                          const znacky_stop_obj = vyber_stop_obj_znacky(
                            new Date(param._d)
                          );

                          var polozky = data.filter(function (objekt) {
                            return (
                              rowSelectionModel.includes(objekt.id) &&
                              !znacky_stop_obj.includes(objekt.znacka.trim())
                            );
                          });
                          var model = [];
                          polozky.map((polozka) => {
                            model.push(polozka.id);
                          });
                          // console.log(model)
                          // console.log(newRowSelectionModel)
                          if (!areArraysEqual(model, rowSelectionModel)) {
                            swal(
                              "Pozor!",
                              "Některé z položek mají objednání blokováno. Nelze je přidat do přesunu.",
                              "info"
                            );
                          }
                          setRowSelectionModel(model);
                          setDatumBuducehoZapisu(param);
                        }}
                        minDate={moment(datumOd)}
                        maxDate={moment(datumDo)}
                        sx={stylingPicker}
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.saveArea}>
            <div className={styles.tre_pok}>
              <div
                onClick={async () => {
                  back();
                }}
                className={styles.save}
              >
                Zpět
              </div>
            </div>
            <div className={styles.tre_pok}>
              <div
                onClick={async () => {
                  if (rowSelectionModel.length === 0) {
                    swal(
                      "Chyba!",
                      "Musíte zvolit položky, které chcete přesunout!",
                      "error"
                    );
                    return;
                  }
                  var now = new Date();
                  now.setHours(0, 0, 0, 0);
                  if (
                    existuju_zaznamy_v_kosiku(new Date(datumBuducehoZapisu._d))
                  ) {
                    swal({
                      content: {
                        element: "div",
                        attributes: {
                          innerHTML: text_zapis_kopije(
                            datumBuducehoZapisu._d,
                            true
                          ),
                        },
                      },
                      icon: "info",
                      buttons: ["Ne ", "Ano"],
                    }).then(async (willShow) => {
                      if (willShow) {
                        setLayoutActive(true);
                        setLayoutText(
                          "Vytvářím přesun položek ze dne  " +
                            validnyDatum() +
                            " na den " +
                            moment(
                              datumBuducehoZapisu._d,
                              "ddd MMM DD YYYY HH:mm:ss"
                            ).format("D.M.YYYY")
                        );
                        var polozky = data.filter(function (objekt) {
                          return rowSelectionModel.includes(objekt.id);
                        });

                        await zapis_presun(
                          new Date(datumBuducehoZapisu._d),
                          polozky,
                          new Date(datum_presun),
                          objednat_data.scrollPosition,
                          hlavicka
                        );
                      }
                    });
                  } else {
                    swal({
                      content: {
                        element: "div",
                        attributes: {
                          innerHTML: text_zapis_kopije(
                            datumBuducehoZapisu._d,
                            false
                          ),
                        },
                      },
                      icon: "info",
                      buttons: ["Ne ", "Ano"],
                    }).then(async (willShow) => {
                      if (willShow) {
                        setLayoutActive(true);
                        setLayoutText(
                          "Vytvářím přesun položek ze dne  " +
                            validnyDatum() +
                            " na den " +
                            moment(
                              datumBuducehoZapisu._d,
                              "ddd MMM DD YYYY HH:mm:ss"
                            ).format("D.M.YYYY")
                        );

                        var polozky = data.filter(function (objekt) {
                          return rowSelectionModel.includes(objekt.id);
                        });

                        await zapis_presun(
                          new Date(datumBuducehoZapisu._d),
                          polozky,
                          new Date(datum_presun),
                          objednat_data.scrollPosition,
                          hlavicka
                        );

                        // await objednat(
                        //   datum_kopie,
                        //   objednat_data.scrollPosition,
                        //   moment(datum_kopie, "ddd MMM DD YYYY HH:mm:ss").format(
                        //     "YYYY-MM-DDTHH:mm:ss"
                        //   ),
                        //   hlavicka,
                        //   now.getTime() > datum_kopie.getTime() ? "ANO" : null
                        // );
                        // buttony.map((button, index) => {
                        //   var date = new Date(button.datum);
                        //   if (date.getTime() === datum_kopie.getTime()) {
                        //     aktualizuj_kosik(button.datum, index);
                        //   }
                        // });
                        setLayoutActive(false);
                        setLayoutText("");
                        setDatumBuducehoZapisu(
                          moment(new Date().setHours(0, 0, 0))
                        );
                        //setVisible(false);
                      }
                    });
                  }
                }}
                className={styles.save}
              >
                Přesun položek
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </Modal>
      <ToastContainer />
    </>
  );
};
export default App;
