import { createSlice } from '@reduxjs/toolkit';

export const tableObjednavkyBBObjednavkaSlice = createSlice({
    name: 'objednavkyBBObjednavkaTable',
    initialState: {
        data: [],
        loading: true,
        title: "Obejdnávka",
        button: "Odeslat objednávku",
        date: null,
        volba: "",
    },
    reducers: {
        objednavkyBBObjednavkaTable: (state, action) => {
            console.log("data + loading: false")
            state.data = action.payload
            state.loading = false
        },
        loadingObjednavkyBBObjednavkaTable: (state) => {
            console.log("loading: true")
            state.loading = true
        },
        setButton: (state, action) => {
            state.button = action.payload
        },
        setTitle: (state, action) => {
            state.title = action.payload
        },
        setDate: (state, action) => {
            state.date = action.payload
        },
        setVolba: (state, action) => {
            state.volba = action.payload
        }

    },
});

export const { objednavkyBBObjednavkaTable, loadingObjednavkyBBObjednavkaTable, setButton, setTitle, setDate, setVolba} = tableObjednavkyBBObjednavkaSlice.actions;

export const objednavkyBBObjednavkaTableData = state => state.objednavkyBBObjednavkadata;


export default tableObjednavkyBBObjednavkaSlice.reducer;
