import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import Page from "./components/Page";
import HomePage from "./screens/HomePage";
import Home from "./screens/Home";
import "./styles/app.sass";

import StavSkladu from "./screens/StavSkladu";
import MalaInventura from "./screens/MalaInventura";
import Reklamace from "./screens/Reklamace";
import ReklamaceExtNova from "./screens/ReklamaceExt_nova";
import ReklamaceExt from "./screens/ReklamaceExt";
import ReklamacBB from "./screens/ReklamaceBB";
import ReklamaceInt from "./screens/ReklamaceInt";
import ReklamaceInt_nova from "./screens/ReklamaceInt_nova";
import ReklamaceBB_nova from "./screens/ReklamaceBB_nova";
import PrijemSurovin from "./screens/Prijemky/PrijemSurovin";
import NabidnoutPobocke from "./screens/NabidnoutPobocke";
import PrevodSurovin from "./screens/PrevodSurovin";
import Prijemky from "./screens/Prijemky";
import ReklamaceTrellis from "./screens/ReklamaceTrellis";

import Zamestnanci from "./screens/Zamestnanci";
import Objednavky from "./screens/Objednavky";
import Objednavky_typERP_O from "./screens/Objednavky_typERP_O";
import Objednavky_typERP_BB from "./screens/Objednavky_typERP_BB";
import Notification from "./screens/Notification";
import Objednavky_ostatne from "./screens/Objednavky_ostatne";
import Objednavky_ostatne_objednavka from "./screens/Objednavky_ostatne_objednavka";
import Objednavky_BB from "./screens/Objednavky_BB";
import Objednavky_BB_objednavka from "./screens/Objednavky_BB_objednavka";

import SignIn from "./screens/SignIn";
import ZmenaHesla from "./screens/ZmenaHesla";

import Kalendar from "./screens/Kalendar";
import PageCalendar from "./components/PageCalendar";
import ProhlizeniSestav from "./screens/ProhlizeniSestav";
import TestPage from "./screens/TestPage";

import Moduly from "./screens/Moduly";
import Moduly_vyber_stredisko_zoznam from "./screens/Moduly_vyber_stredisko_zoznam";
import Moduly_vyber_ico_zoznam from "./screens/Moduly_vyber_ico_zoznam";

import Sklad from "./screens/Menu_page/Sklad";
import Vyroba from "./screens/Menu_page/Vyroba";
import Dochadzka from "./screens/Menu_page/Dochadzka";
import Menezerske_volby from "./screens/Menu_page/Menezerske_volby";
import Reporty from "./screens/Menu_page/Reporty";
import Ciselniky from "./screens/Menu_page/Ciselniky";
import DodavateleAsmlouvy from "./screens/Menu_page/DodavateleAsmlouvy";
import VolbyAdministratora from "./screens/Menu_page/VolbyAdministratora";

import Dzml_prc from "./screens/Ciselniky_prc/Dzml_prc";
import Dcen_prc from "./screens/Ciselniky_prc/Dcen_prc";
import Cenl_prc from "./screens/Ciselniky_prc/Cenl_prc";
import Cenp_prc from "./screens/Ciselniky_prc/Cenp_prc";
import Cenc_prc from "./screens/Ciselniky_prc/Cenc_prc";
import Cen_prc from "./screens/Ciselniky_prc/Cen_prc";
import Kurz_prc from "./screens/Ciselniky_prc/Kurz_prc";
import Pozv_prc from "./screens/Ciselniky_prc/Pozv_prc";
import Skuv_prc from "./screens/Ciselniky_prc/Skuv_prc";
import Sku_prc from "./screens/Ciselniky_prc/Sku_prc";
import Pod_prc from "./screens/Ciselniky_prc/Pod_prc";
import Poku_prc from "./screens/Ciselniky_prc/Poku_prc";
import Drhp_prc from "./screens/Ciselniky_prc/Drhp_prc";
import Dpcc_prc from "./screens/Ciselniky_prc/Dpcc_prc";
import Prsd_prc from "./screens/Ciselniky_prc/Prsd_prc";
import Csk_prc from "./screens/Ciselniky_prc/Csk_prc";
import Oso_prc from "./screens/Ciselniky_prc/Oso_prc";
import Vypp_prc from "./screens/Ciselniky_prc/Vypp_prc";
import Agen_prc from "./screens/Ciselniky_prc/Agen_prc";
import Numk_prc from "./screens/Ciselniky_prc/Numk_prc";
import Dpp_prc from "./screens/Ciselniky_prc/Dpp_prc";
import Icod from "./screens/Ciselniky_prc/Icod";
import Dkpp_prc from "./screens/Ciselniky_prc/Dkpp_prc";
import Tpsm_prc from "./screens/Ciselniky_prc/Tpsm_prc";
import Tysm_prc from "./screens/Ciselniky_prc/Tysm_prc";
import Doso_prc from "./screens/Ciselniky_prc/Doso_prc";
import Stat_prc from "./screens/Ciselniky_prc/Stat_prc";
import Ico1_prc from "./screens/Ciselniky_prc/Ico1_prc";
import Inyv_p from "./screens/Ciselniky_prc/Reks_prc";
import Prevod_p from "./screens/Ciselniky_prc/Prevod_p";
import Dl_prc from "./screens/Ciselniky_prc/Dl_prc";
import Aut_prc from "./screens/Ciselniky_prc/Aut_prc";
import Tra_prc from "./screens/Ciselniky_prc/Tra_prc";
import Trnz_prc from "./screens/Ciselniky_prc/Trnz_prc";
import Ts_prc from "./screens/Ciselniky_prc/Ts_prc";
import Icoo_prc from "./screens/Ciselniky_prc/Icoo_prc";
import Obl_prc from "./screens/Ciselniky_prc/Obl_prc";
import Tst_prc from "./screens/Ciselniky_prc/Tst_prc";
import Nor_prc from "./screens/Ciselniky_prc/Nor_prc";
import Skub_prc from "./screens/Ciselniky_prc/Skub_prc";
import Cskb_prc from "./screens/Ciselniky_prc/Cskb_prc";
import Pnor_prc from "./screens/Ciselniky_prc/Pnor_prc";
import Reks_prc from "./screens/Ciselniky_prc/Reks_prc";
import Eset_prc from "./screens/Ciselniky_prc/Eset_prc";
import Nahn_prc from "./screens/Ciselniky_prc/Nahn_prc";
import Fifk_prc from "./screens/Ciselniky_prc/Fifk_prc";
import Fifc_prc from "./screens/Ciselniky_prc/Fifc_prc";
import Fifp_prc from "./screens/Ciselniky_prc/Fifp_prc";
import Skpr_prc from "./screens/Ciselniky_prc/Skpr_prc";

import Usrg_prc from "./screens/Ciselniky_prc/Usrg_prc";
import Usrm_prc from "./screens/Ciselniky_prc/Usrm_prc";
import User_prc from "./screens/Ciselniky_prc/User_prc";
import Accn_prc from "./screens/Ciselniky_prc/Accn_prc";
import Dfcp_prc from "./screens/Ciselniky_prc/Dfcp_prc";
import Danp_prc from "./screens/Ciselniky_prc/Danp_prc";
import Drho_prc from "./screens/Ciselniky_prc/Drho_prc";
import Dan_prc from "./screens/Ciselniky_prc/Dan_prc";
import Men_prc from "./screens/Ciselniky_prc/Men_prc";
import Osn_prc from "./screens/Ciselniky_prc/Osn_prc";
import { login, loginData } from "./screens/SignIn/redux/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { notifikaciaUrl } from "./features/urlHelper";
import { HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import {
  setConnectionCiselniky,
  setConnectionNotifications,
  setConnectionNotificationsUnread,
} from "./screens/SignIn/redux/signalRSlice";
import { useHistory } from "react-router";
import Users from "./screens/Ciselniky_prc/Users";
import Users_ico from "./screens/Ciselniky_prc/Users_ico";
import KODYS_TRUCKROUTES from "./screens/Ciselniky_prc/KODYS_TRUCKROUTES";
import KODYS_TRUCKROUTES_DETAILS from "./screens/Ciselniky_prc/KODYS_TRUCKROUTES_DETAILS";

import Users_activity from "./screens/Users_activity";

function App() {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [logindata, setLogindata] = useState(JSON.parse(window.sessionStorage.getItem("logindata") !== null ? window.sessionStorage.getItem("logindata") : "{}"));
  //const logindata = useSelector(loginData);
  const dispatch = useDispatch();
  const navigate = useHistory();

  // if (window.performance) {
  //   if (performance.navigation.type == 1) {
  //     alert("This page is reloaded");
  //     console.log(logindata)
  //   } else {
  //     alert("This page is not reloaded");
  //   }
  // }

  useEffect(() => {
    console.log(logindata)
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/moduly"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "C" ? (
                <div style={{ padding: 24 }}>
                  <Moduly />
                </div>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/moduly_vyber_stredisko_zoznam"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "O" ? (
                <div style={{ padding: 24 }}>
                  <Moduly_vyber_stredisko_zoznam />
                </div>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/moduly_vyber_ico_zoznam"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "R" ? (
                <div style={{ padding: 24 }}>
                  <Moduly_vyber_ico_zoznam />
                </div>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        {/*nepouziva sa*/}
        {/* <Route
          exact
          path="/users_ico"
          render={() => (
            <>
              {logindata.UserData.vyber_ico ? (
                <Page>
                  <Users_ico />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        /> */}
        <Route
          exact
          path="/users"
          render={() => (
            <>
              {logindata.UserData.admin || logindata.UserData.admin_bb || logindata.UserData.admin_croco || logindata.UserData.admin_obj ? (
                <Page>
                  <Users />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/users_activity"
          render={() => (
            <>
              {logindata.UserData.admin ? (
                <Page>
                  <Users_activity />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/kodys_truckroutes"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "W" ? (
                <Page>
                  <KODYS_TRUCKROUTES />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/kodys_truckroutes_details"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "W" ? (
                <Page>
                  <KODYS_TRUCKROUTES_DETAILS />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/prehled"
          render={() => (
            <>
              {logindata.UserData !== undefined && (logindata.UserData.typErp === "B" ||
               logindata.UserData.typErp === "C") ? (
                <HomePage title="Dnešní přehled">
                  <Home />
                </HomePage>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/kalendar"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "B" ? (
                <PageCalendar>
                  <Kalendar />
                </PageCalendar>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/zamestnanci"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "B" ? (
                <Page title="Zaměstnanci">
                  <Zamestnanci />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/objednavkyOstatne"
          render={() => (
            <>
              {logindata.UserData !== undefined && (logindata.UserData.typErp === "O" || logindata.UserData.typErp === "R") ? (
                <Page>
                  <Objednavky_typERP_O />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/objednavkyBB"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "B" ? (
                <Page>
                  <Objednavky_typERP_BB />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/reklamaceExt_nova"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "R" ? (
                <Page>
                  <ReklamaceExtNova />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/reklamaceExt"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "R" ? (
                <Page>
                  <ReklamaceExt />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/reklamaceTrellis"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "B" ? (
                <Page>
                  <ReklamaceTrellis />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/reklamace/reks_prc"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "B" ? (
                <Page>
                  <ReklamaceInt />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
         <Route
          exact
          path="/reklamace/prehled"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "B" ? (
                <Page>
                  <ReklamacBB />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/reklamace/nova"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "B" ? (
                <Page>
                  <ReklamaceBB_nova />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />

        <Route
          exact
          path="/objednavky"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "B" ? (
                <Page>
                  <Objednavky />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/objednavkyOstatnePrehled"
          render={() => (
            <>
              {logindata.UserData !== undefined && (logindata.UserData.typErp ==="O" || logindata.UserData.typErp === "R") ? (
                <Page>
                  <Objednavky_ostatne />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          //nepouziva sa
          path="/objednavky_ostatne/objednavka"
          render={() => (
            <>
              {logindata.UserData !== undefined && (logindata.UserData.typErp === "O" || logindata.UserData.typErp === "R") ? (
                <Page>
                  <Objednavky_ostatne_objednavka />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
          <Route
          exact
          path="/objednavkyBBPrehled"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "B" ? (
                <Page>
                  <Objednavky_BB />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          //nepouziva sa
          path="/objednavky_BB/objednavka"
          render={() => (
            <>
              {logindata.UserData !== undefined && (logindata.UserData.typErp === "B") ? (
                <Page>
                  <Objednavky_BB_objednavka />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/notifications"
          render={() => (
            <Page>
              <Notification />
            </Page>
          )}
        />
        <Route
          exact
          path="/vytvorit/stavSkladu"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "B" ? (
                <Page>
                  <StavSkladu />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/vytvorit/malaInventura"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "B" ? (
                <Page>
                  <MalaInventura />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/vytvorit/reklamace"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "B" ? (
                <Page>
                  <Reklamace />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/vytvorit/prijemSurovin"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "B" ? (
                <Page>
                  <PrijemSurovin />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/vytvorit/prijemky"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "B" ? (
                <Page>
                  <Prijemky />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/vytvorit/nabidnoutPobocke"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "B" ? (
                <Page>
                  <NabidnoutPobocke />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/vytvorit/prevodSurovin"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "B" ? (
                <Page>
                  <PrevodSurovin />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/nastroje/prohlizeniSestav"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "B" ? (
                <Page>
                  <ProhlizeniSestav />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/nastroje/testPage"
          render={() => (
            <>
              {logindata.UserData !== undefined && logindata.UserData.typErp === "B" ? (
                <Page>
                  <TestPage />
                </Page>
              ) : (
                <Page notFound={true}></Page>
              )}
            </>
          )}
        />
        <Route
          exact
          path="/ciselniky/icod_prc"
          render={() => (
            <Page>
              <Icod />
            </Page>
          )}
        />
        <Route
          exact
          path="/reporty"
          render={() => (
            <Page>
              <Reporty />
            </Page>
          )}
        />
        <Route
          exact
          path="/sklad"
          render={() => (
            <Page>
              <Sklad />
            </Page>
          )}
        />
        <Route
          exact
          path="/vyroba"
          render={() => (
            <Page>
              <Vyroba />
            </Page>
          )}
        />
        <Route
          exact
          path="/dochadzka"
          render={() => (
            <Page>
              <Dochadzka />
            </Page>
          )}
        />
        <Route
          exact
          path="/ciselniky"
          render={() => (
            <Page>
              <Ciselniky />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby"
          render={() => (
            <Page>
              <Menezerske_volby />
            </Page>
          )}
        />
        <Route
          exact
          path="/dodavatele&smlouvy"
          render={() => (
            <Page>
              <DodavateleAsmlouvy />
            </Page>
          )}
        />
        <Route
          exact
          path="/mod_adm"
          render={() => (
            <Page>
              <VolbyAdministratora />
            </Page>
          )}
        />
        <Route
          exact
          path="/ciselniky/dzml_prc"
          render={() => (
            <Page>
              <Dzml_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/ciselniky/pozv_prc"
          render={() => (
            <Page>
              <Pozv_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/ciselniky/oso_prc"
          render={() => (
            <Page>
              <Oso_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/ciselniky/skuv_prc"
          render={() => (
            <Page>
              <Skuv_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/csk_prc"
          render={() => (
            <Page>
              <Csk_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/sku_prc"
          render={() => (
            <Page>
              <Sku_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/pod_prc"
          render={() => (
            <Page>
              <Pod_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/dpcc_prc"
          render={() => (
            <Page>
              <Dpcc_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/obl_prc"
          render={() => (
            <Page>
              <Obl_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/ciselniky/dcen_prc"
          render={() => (
            <Page>
              <Dcen_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/ciselniky/cen_prc"
          render={() => (
            <Page>
              <Cen_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/ciselniky/osn_prc"
          render={() => (
            <Page>
              <Osn_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/ciselniky/kurz_prc"
          render={() => (
            <Page>
              <Kurz_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/prsd_prc"
          render={() => (
            <Page>
              <Prsd_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/tst_prc"
          render={() => (
            <Page>
              <Tst_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/dochadzka/vypp_prc"
          render={() => (
            <Page>
              <Vypp_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/dochadzka/agen_prc"
          render={() => (
            <Page>
              <Agen_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/numk_prc"
          render={() => (
            <Page>
              <Numk_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/dpp_prc"
          render={() => (
            <Page>
              <Dpp_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/dkpp_prc"
          render={() => (
            <Page>
              <Dkpp_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/nor_prc"
          render={() => (
            <Page>
              <Nor_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/skub_prc"
          render={() => (
            <Page>
              <Skub_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/cskb_prc"
          render={() => (
            <Page>
              <Cskb_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/dochadzka/tpsm_prc"
          render={() => (
            <Page>
              <Tpsm_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/ciselniky/tysm_prc"
          render={() => (
            <Page>
              <Tysm_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/doso_prc"
          render={() => (
            <Page>
              <Doso_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/stat_prc"
          render={() => (
            <Page>
              <Stat_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/ico1_prc"
          render={() => (
            <Page>
              <Ico1_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/icoo_prc"
          render={() => (
            <Page>
              <Icoo_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/inyv_p"
          render={() => (
            <Page>
              <Inyv_p />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/prevod_p"
          render={() => (
            <Page>
              <Prevod_p />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/aut_prc"
          render={() => (
            <Page>
              <Aut_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/poku_prc"
          render={() => (
            <Page>
              <Poku_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/drhp_prc"
          render={() => (
            <Page>
              <Drhp_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/tra_prc"
          render={() => (
            <Page>
              <Tra_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/trnz_prc"
          render={() => (
            <Page>
              <Trnz_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/ciselniky/ts_prc"
          render={() => (
            <Page>
              <Ts_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/ciselniky/cenl_prc"
          render={() => (
            <Page>
              <Cenl_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/ciselniky/cenp_prc"
          render={() => (
            <Page>
              <Cenp_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/cenc_prc"
          render={() => (
            <Page>
              <Cenc_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/ciselniky/danp_prc"
          render={() => (
            <Page>
              <Danp_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/ciselniky/drho_prc"
          render={() => (
            <Page>
              <Drho_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/dl_prc"
          render={() => (
            <Page>
              <Dl_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/skpr_prc"
          render={() => (
            <Page>
              <Skpr_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/dan_prc"
          render={() => (
            <Page>
              <Dan_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/men_prc"
          render={() => (
            <Page>
              <Men_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/pnor_prc"
          render={() => (
            <Page>
              <Pnor_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/nahn_prc"
          render={() => (
            <Page>
              <Nahn_prc />
            </Page>
          )}
        />
         <Route
          exact
          path="/manazerske&volby/fifk_prc"
          render={() => (
            <Page>
              <Fifk_prc />
            </Page>
          )}
        />
         <Route
          exact
          path="/manazerske&volby/fifc_prc"
          render={() => (
            <Page>
              <Fifc_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/manazerske&volby/fifp_prc"
          render={() => (
            <Page>
              <Fifp_prc />
            </Page>
          )}
        />
         <Route
          exact
          path="/manazerske&volby/eset_prc"
          render={() => (
            <Page>
              <Eset_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/mod_adm/usrg_prc"
          render={() => (
            <Page>
              <Usrg_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/mod_adm/usrm_prc"
          render={() => (
            <Page>
              <Usrm_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/mod_adm/user_prc"
          render={() => (
            <Page>
              <User_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/mod_adm/accn_prc"
          render={() => (
            <Page>
              <Accn_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/mod_adm/dfcp_prc"
          render={() => (
            <Page>
              <Dfcp_prc />
            </Page>
          )}
        />
        <Route
          exact
          path="/zmenaHesla"
          render={() => (
            <Page>
              <ZmenaHesla />
            </Page>
          )}
        />
        <Route exact path="/" render={() => <SignIn logindata={logindata} setLogindata={setLogindata}/>} />
      </Switch>
    </Router>
  );
}

export default App;
