import React, { useState, useEffect } from "react";
import cn from "classnames";

import styles from "./Dropdown.module.sass";
import Tooltip from "../Tooltip";
import remove_accents from "remove-accents";
import { useDispatch, useSelector } from "react-redux";
import ClickAwayListener from "@mui/base/ClickAwayListener";

const Dropdown = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  options,
  label,
  tooltip,
  small,
  upBody,
  title,
}) => {
  const [visible, setVisible] = useState(false);
  const [helpValue, setHelpValue] = useState(
    title === "prehlad" ? value.slice(0, 3) : value
  );
  const dispatch = useDispatch();

  return (
    // <ClickAwayListener onClickAway={() => {
    //   if(title === 'prehlad'){
    //     setHelpValue(value.slice(0,3))
    //   }else{
    //     setHelpValue(value)
    //   }
    //   setVisible(false)}}>
    <>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <div
          className={cn(styles.head, classDropdownHead)}
          onClick={() => {
            setHelpValue("");
            setVisible(!visible);
          }}
        >
          <input
            name="input"
            value={helpValue}
            onChange={(e) => {
              if (title === "prehlad") {
                setHelpValue(e.target.value.slice(0, 3));
              } else {
                setHelpValue(e.target.value);
              }
            }}
            className={styles.selection}
          ></input>
        </div>
        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
          {options.map((x, index) => {
            if (
              String(remove_accents(x.toUpperCase())).includes(
                remove_accents(helpValue.toUpperCase())
              )
            ) {
              return (
                <div
                  className={cn(styles.option, {
                    [styles.selectioned]: x === value,
                  })}
                  onClick={() => {
                    if (title === "prehlad") {
                      setHelpValue(x.slice(0, 3));
                    } else {
                      setHelpValue(x);
                    }
                    setVisible(false);
                    setValue(x);
                  }}
                  key={index}
                >
                  {x}
                </div>
              );
            }
          })}
        </div>
      </div>
    </>
    // </ClickAwayListener>
  );
};

export default Dropdown;
