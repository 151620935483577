import Modal from "../../Modal";
import Card from "../../Card";
import Icon from "../../Icon";
import Search from "../../Search";
import styles from "./../Modaly.module.sass";
import remove_accents from "remove-accents";
import cn from "classnames";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { search, searchData } from "../../Search/redux/searchSlice";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { errorHandleDialog } from "../../../features/errorDialog";

const App = ({
  className,
  url,
  visible,
  setVisible,
  typ,
  value,
  setValue,
  data,
  idField,
}) => {
  const dispatch = useDispatch();
  const searchdata = useSelector(searchData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );

  useEffect(() => {
    if (!visible) {
      // Ak sa modal zavrie (visible je false), vymažeme inputy
      dispatch(
        search({ data: { ...searchdata, ["searchVyberZoZoznamu"]: "" } })
      );
    }
  }, [visible]);

  //if (!visible) return null;

  return (
    <Modal outerClassName={styles.outerZaznamProblemu} visible={visible}>
      <div className={styles.ico}>
        <button
          className={styles.icon}
          onClick={() => {
            setVisible(false);
          }}
        >
          <Icon name="close" size="20" />
        </button>
      </div>
      <Card
        bezPaddingu={false}
        className={cn(styles.card, className)}
        title={typ}
        classTitle="title-red"
      >
        <Search field={"searchVyberZoZoznamu"} />
        <br />
        <div
          style={{ height: window.innerHeight - 280 }}
          className={styles.upperContentScr}
        >
          {data.length > 0 &&
            data
              .filter(
                (xy) =>
                  String(remove_accents(xy["nadpis"].toUpperCase())).includes(
                    remove_accents(
                      searchdata.searchVyberZoZoznamu.toUpperCase()
                    )
                  ) ||
                  String(remove_accents(xy["label"].toUpperCase())).includes(
                    remove_accents(
                      searchdata.searchVyberZoZoznamu.toUpperCase()
                    )
                  )
              )
              .map((x, index) => (
                <div key={index}>
                  <div
                    onClick={() => {
                      setValue(x);
                      setVisible(false);
                    }}
                    className={styles.itemZmenaDodavatele}
                  >
                    <div className={cn(styles.avatar)}>
                      <img src={"/images/content/kosik.png"} alt="Avatar" />
                    </div>
                    <div
                      className={
                        value[idField] !== undefined &&
                        x.znacka == value[idField]
                          ? styles.upperContentRightA
                          : styles.upperContentRightP
                      }
                    >
                      <span style={{ fontWeight: "bold" }}>{x.nadpis}</span>
                      <br />
                      <span style={{ color: "gray" }}>{x.label}</span>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </Card>
    </Modal>
  );
};
export default App;
