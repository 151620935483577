import Card from "../../../components/Card";
import styles from "./Modaly.module.sass";
import cn from "classnames";
import Search from "../../../components/Search";
import Table from "./Table";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { externyDodavatelTableData } from "./Table/redux/tableExternyDodavatelSlice";
import { modalData, modal } from "../../../components/Modaly/redux/modalSlice";
import {
  zmena,
  setDodaciList,
  zmenaData,
  loadingZmena,
} from "../../../components/Modaly/ModalZmena/redux/zmenaSlice";
import ModalZmena from "../../../components/Modaly/ModalZmena";
import { dodavatelUrl } from "../../../features/urlHelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { errorHandleDialog } from "../../../features/errorDialog";
import axios from "axios";
import Dropzone from "../../../components/DropZone";
const App = ({ className, editExOver, setEditExOver, myFiles, setMyFiles }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const modaldata = useSelector(modalData);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const zmenadata = useSelector(zmenaData);
  const externyDodavatelTabledata = useSelector(externyDodavatelTableData);
  const [visible, setVisible] = useState(false);
  function pocet() {
    let i = 0;
    externyDodavatelTabledata.data.map((row) => {
      if (row.pocet > 0) {
        i++;
      }
    });
    return i;
  }

  return (
    <div>
      <div className={styles.Content}>
        <div className={styles.ContentLeft}>
          <div className={styles.upperContent}>
            <div className={cn(styles.avatar)}>
              <img src={"/images/content/home.png"} alt="Avatar" />
            </div>
            <div className={styles.cieloveStrediskoContent}>
              <div className={styles.cieloveStrediskoButton}>
                <div style={{ marginRight: 5 }}>Dodavatel</div>
                <div>
                  <button
                    className={styles.buttonZmena}
                    onClick={async () => {
                      // dispatch(
                      //   modal({ data: { ...modaldata, ZmenaDodavatela: true } })
                      // );
                      await axios
                        .get(dodavatelUrl, {
                          headers: {
                            Authorization: "Bearer " + logindata.Token,
                          },
                        })
                        .then(function (response) {
                          console.log(response);
                          const data = response.data.map((item) => ({
                            ...item,
                            vybrane: false,
                          }));
                          dispatch(loadingZmena());
                          dispatch(zmena(data));
                          setVisible(true);
                        })
                        .catch(function (error) {
                          errorHandleDialog(error.response, navigate, dispatch);
                        });
                    }}
                  >
                    {zmenadata.isVybrate ? "Změnit" : "Vybrat"}
                  </button>
                </div>
              </div>
              <div className={styles.cieloveStredisko}>
                <div style={{ fontWeight: "bold" }}>
                  <div>
                    {zmenadata.data.map((item) => {
                      if (item.vybrane) {
                        return item.odberatel;
                      }
                    })}
                  </div>
                </div>
                <div style={{ color: "gray" }}>
                  {zmenadata.data.map((item) => {
                    if (item.vybrane) {
                      return "ICO: " + item.ico;
                    }
                  })}
                </div>
                {/* <div style={{ color: "gray" }}>
                  {zmenadata.data.map((item) => {
                    if (item.vybrane) {
                      return "Poznámka: " + item.poznamka;
                    }
                  })}
                </div> */}
              </div>
            </div>
          </div>
          <div className={styles.upperContentInside}>
            <div className={cn(styles.avatar)}>
              <img src={"/images/content/dokument.png"} alt="Avatar" />
            </div>
            <div className={styles.cieloveStrediskoContent}>
              <div className={styles.text_inputik}>
                <div className={styles.text} style={{ marginRight: 5 }}>Dodací list</div>{" "}
                <input
                  onClick={() => {
                    document.getElementsByName("dodList")[0].select();
                  }}
                  name="dodList"
                  value={zmenadata.dodaciList}
                  onChange={(e) => {
                    dispatch(setDodaciList(e.target.value));
                  }}
                  autoComplete="off"
                  className={styles.inputik}
                ></input>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.upperContentInside}>
        <div style={{ width: window.innerWidth > 767 ? 350 : 150 }}>
            <Dropzone
              text={"Vložte fotky"}
              myFiles={myFiles}
              setMyFiles={setMyFiles}
            />
          </div>
        </div>
      </div>
      <div className={styles.spacebeetween}>
        <div className={styles.count}>{pocet()} přijatých</div>
      </div>

      {/* <Search field={"searchexDod"} className={styles.searchHead} />
        <br /> */}
      <Table editExOver={editExOver} setEditExOver={setEditExOver} />
      <ModalZmena
        field={"ico"}
        typ={"Dodavatele"}
        searchValue={"odberatel"}
        visible={visible}
        setVisible={setVisible}
        nadpis={"odberatel"}
        label={[
          { label: "Kod: ", field: "ico" },
          { label: "Ičo : ", field: "iico" },
        ]}
      />
    </div>
  );
};
export default App;
