import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Icon from "../Icon";
import styles from "./DropZone.module.sass";

export default function DropZone({ text, size = 3, myFiles=[], setMyFiles}) {

  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles);
      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: size,
  });

  const removeFile = (file) => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const removeAll = () => {
    setMyFiles([]);
  };

  function formatSizeUnits(bytes) {
    if (bytes >= 1073741824) {
      bytes = (bytes / 1073741824).toFixed(2) + " GB";
    } else if (bytes >= 1048576) {
      bytes = (bytes / 1048576).toFixed(2) + " MB";
    } else if (bytes >= 1024) {
      bytes = (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes > 1) {
      bytes = bytes + " bytes";
    } else if (bytes == 1) {
      bytes = bytes + " byte";
    } else {
      bytes = "0 bytes";
    }
    return bytes;
  }

  const files = myFiles.map((file) => (
    <div key={file.path} className={styles.row}>
      <div className={styles.file_around}>
        <div lassName={styles.file}>
          <div className={styles.IconFile}>
            <i class="fa fa-file-image-o"></i>
          </div>
          <div className={styles.IconFileText} title={file.path}>{file.path.slice(0, 6)} ...</div>
          <div className={styles.IconFileText}>{formatSizeUnits(file.size)}</div>
        </div>
        <div
          className={styles.IconClose}
          onClick={() => {
            removeFile(file);
          }}
        >
          <Icon name="close" size="12" />
        </div>
      </div>
    </div>
  ));

  return (
    <section className={styles.drop_zone_aroud}>
      <div {...getRootProps({ className: styles.dropzone })}>
        <input {...getInputProps()} />
        <p>{text}</p>
        <i class="fa fa-cloud-upload"></i>
      </div>
      <div className={styles.dropzone_under}>
        <div className={styles.files}>{files}</div>
      </div>
    </section>
  );
}
