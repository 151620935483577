import Card from "../../components/Card";
import Icon from "../../components/Icon";
import styles from "./Modaly.module.sass";
import cn from "classnames";
import Search from "../../components/Search";
import Table from "./Table";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import { login, loginData } from "../SignIn/redux/loginSlice";
import {
  VypisDokladuBBHTMLUrl,
  VypisDokladuHTMLUrl,
  menuAkciaUrl,
  reklMenuAkciaUrl,
  zoznamReklamaciiBBUrl,
  zoznamReklamaciiExtUrl,
} from "../../features/urlHelper";
import swal from "sweetalert";
import { useHistory } from "react-router";
import Delete from "../../components/Delete";
import { errorHandleDialog } from "../../features/errorDialog";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import moment from "moment";
import ModalRklamceExt from "../../components/Modaly/ModalRklamceExt";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { modal, modalData } from "../../components/Modaly/redux/modalSlice";

const App = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const modaldata = useSelector(modalData);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [params_to_menu, setParams_to_menu] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, value, params) => {
    console.log(params);
    setAnchorEl(null);
    menu_akcia(value, params.row.cis_rekl);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  function getWidth(width) {
    if (width > 2500) {
      return width - 408;
    } else if (width < 2500 && width > 767) {
      return width - 194;
    } else {
      return width;
    }
  }

  const columns = [
    {
      field: "detail",
      hideable: false,
      headerName: "",
      maxWidth: 35,
      minWidth: 35,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <div
              title="Detail"
              className={styles.icons}
              onClick={(e) => {
                handleClickListItem(e);
                setParams_to_menu(params);
                setCurrentRow(params.row.id);
              }}
            >
              <div className={styles.iconY}>
                <i class="fa fa-bars"></i>
              </div>
            </div>
            <div>
              <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={open && currentRow === params.row.id}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "lock-button",
                  role: "listbox",
                }}
              >
                {params.row.menu.map((option, index) => (
                  <MenuItem
                    key={option.value}
                    onClick={(event) =>
                      handleMenuItemClick(event, option.value, params_to_menu)
                    }
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </>
        );
      },
    },
    {
      field: "datum_rekl",
      hideable: false,
      headerName: "Datum",
      width: (getWidth(window.innerWidth) - 0) * 0.15,
      headerAlign: "start",
      sortable: false,
      filterable: false,
      valueFormatter: (params) => moment(params?.value).format("DD.MM.YYYY"),
    },
    {
      field: "cis_rekl",
      hideable: false,
      headerName: "Číslo reklamace",
      width: (getWidth(window.innerWidth) - 0) * 0.25,
      headerAlign: "start",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className={styles.polozka_reklamace}>
            <i class="fa fa-wrench"></i>
            <div>{params.row.cis_rekl}</div>
          </div>
        );
      },
    },
    {
      field: "wms_text",
      hideable: false,
      headerName: "WMS",
      width: (getWidth(window.innerWidth) - 0) * 0.1,
      headerAlign: "start",
      sortable: false,
      filterable: false,
    },
    {
      field: "vybavene",
      hideable: false,
      headerName: "Stav",
      width: (getWidth(window.innerWidth) - 0) * 0.1,
      headerAlign: "start",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div
            className={
              params.row.vybavene ? styles.polozka_vyb : styles.polozka_res
            }
          >
            {params.row.vybavene ? (
              <div className={styles.icon_green}>
                <i class="fa fa-check"></i>
              </div>
            ) : (
              <div className={styles.icon_orange}>
                <i class="fa fa-clock-o"></i>
              </div>
            )}
            {params.row.vybavene ? <div>Vybavene</div> : <div>V řešení</div>}
          </div>
        );
      },
    },
    {
      field: "doklad",
      hideable: false,
      headerName: "Doklad",
      width: (getWidth(window.innerWidth) - 0) * 0.1,
      headerAlign: "start",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <button
            onClick={async () => {
              await axios
                .post(
                  VypisDokladuBBHTMLUrl,
                  {
                    stredisko: logindata.UserData.vybrane_stredisko.kod,
                    doklad: params.row.cis_rekl,
                  },
                  {
                    headers: { Authorization: "Bearer " + logindata.Token },
                  }
                )
                .then(function (response) {
                  console.log(response);
                  if (response.data.html !== null) {
                    dispatch(
                      modal({
                        data: {
                          ...modaldata,
                          ZobrazHtml: true,
                          HtmlData: response.data.html,
                          ZobrazHtml_print: response.data.print,
                          ZobrazHtml_text: response.data.text,
                        },
                      })
                    );
                  }
                })
                .catch(function (error) {
                  errorHandleDialog(error.response, navigate, dispatch);
                });
            }}
            className={styles.polozka_doklad}
          >
            <div className={styles.icon_red}>
              <i class="fa fa-file-pdf-o"></i>
            </div>

            <div>Doklad</div>
          </button>
        );
      },
    },
    {
      field: "nazov",
      hideable: false,
      headerName: "Položky",
      width: (getWidth(window.innerWidth) - 35) * 0.3,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        console.log(params);
        return (
          <div className={styles.polozka}>
            <div className={styles.popis}>
              <div className={styles.nazov}>{params.row.nazov}</div>
              <div className={styles.znacka}>{params.row.znacka}</div>
            </div>
            <div className={styles.content}>
              <div className={styles.obrazok}>
                <img src="/images/content/bageta_1.jpg" alt={params.row.text} />
              </div>
              <div className={styles.overlay_text}>
                <p>{params.row.mnozstvo.toString() + params.row.mj}</p>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    result(logindata.UserData.ico);
  }, []);

  async function menu_akcia(akcia, cis_rekl) {
    await axios
      .post(
        reklMenuAkciaUrl,
        {
          menu_akcia: akcia,
          //stredisko: logindata.UserData.vybrane_stredisko !== undefined ? logindata.UserData.vybrane_stredisko.kod : null,
          ico:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
          cis_rekl: cis_rekl,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        if (akcia === "Odeslání do WMS znova" || akcia === "Odeslání do WMS")
          setData(response.data.data);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function result() {
    await axios
      .post(
        zoznamReklamaciiBBUrl,
        {
          stredisko: logindata.UserData.vybrane_stredisko.kod,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        console.log(response);
        setData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  return (
    <div>
      <div className={styles.nadpis}>
        <div>Přehled reklamací</div>
      </div>

      <Table columns={columns} data={data} loading={loading} />
    </div>
  );
};
export default App;
