import React, { useState, useEffect } from "react";
import styles from "./Sklad.module.sass";
import Card from "../../components/Card";
import { getLicensePremium } from "../../features/License";
import {
  DataGridPremium,
  csCZ,
  gridExpandedSortedRowIdsSelector,
  gridVisibleColumnDefinitionsSelector,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import Search from "../../components/Search";
import {
  aktualizaciaKosikaUrl,
  kopiaObjednavkyUrl,
  kopiaZapisObjednavkyUrl,
  nacitajKosikObjOstatneUrl,
  notifikaciaUrl,
  nulovanieKosikaUrl,
  objednavky_typERP_OUrl,
  objednavky_typERP_O_objednavkaUrl,
  objednavky_typERP_O_zapisUrl,
  presunPoloziekObjednavkyUrl,
  presunPoloziekObjednavkyZapisUrl,
  removeAllConnUrl,
} from "../../features/urlHelper";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { login, loginData } from "../SignIn/redux/loginSlice";
import Dropdown from "./Dropdown";
import DDTagy from "./DDTagy";
import useDarkMode from "@fisch0920/use-dark-mode";
import { search, searchData } from "../../components/Search/redux/searchSlice";
import remove_accents from "remove-accents";
import ContentLoader from "react-content-loader";
import {
  pickerObjednavky_typERP_OData,
  pickerObjednavky_typERP_O,
} from "./Dropdown/redux/PickerObjednavky_typERP_OSlice";
import { modalData, modal } from "../../components/Modaly/redux/modalSlice";
import {
  pickerTagyObjednavky_typERP_O,
  pickerTagyObjednavky_typERP_OData,
} from "./DDTagy/redux/PickerTagyObjednavky_typERP_OSlice";
import {
  objednavky_typERP_OData,
  objednavky_typERP_ORows,
  objednavky_typERP_OAllRows,
} from "./redux/objednavky_typERP_OSlice";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { errorHandleDialog } from "../../features/errorDialog";
import LoadingOverlay from "react-loading-overlay-nextgen";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";
import {
  setConnectionObjednavky,
  setConnectionObjednavkyID,
  signalRData,
} from "../SignIn/redux/signalRSlice";
import { validateNumberWithMaxDecimals } from "../../features/validateNumberWithMaxDecimals";
import ModalKosik from "./../../components/Modaly/ModalKosik";
import ModalKopiaObjednavky from "./../../components/Modaly/ModalKopiaObjednavky";
import ModalPresunPolozekNaInyDen from "../../components/Modaly/ModalPresunPolozekNaInyDen";
import moment from "moment";

const Objednavky_typERP_O = () => {
  const apiRef = useGridApiRef(null);
  const darkMode = useDarkMode(false);
  const navigate = useHistory();
  const dispatch = useDispatch();
  const searchdata = useSelector(searchData);
  //const logindata = useSelector(loginData);
  const logindata = JSON.parse(
    window.sessionStorage.getItem("logindata") !== null
      ? window.sessionStorage.getItem("logindata")
      : "{}"
  );
  const pickerObjednavky_typERP_Odata = useSelector(
    pickerObjednavky_typERP_OData
  );
  const modaldata = useSelector(modalData);
  const pickerTagyObjednavky_typERP_Odata = useSelector(
    pickerTagyObjednavky_typERP_OData
  );
  const objednavky_typERP_Odata = useSelector(objednavky_typERP_OData);

  const [columns, setColumns] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState("...");
  const [loaderText, setLoaderText] = useState("");
  const [focusValue, setFocusValue] = useState("");
  const [allData, setAllData] = useState([]);
  const [isIn, setIsIn] = useState(false);
  const [stop_obj_znacky, setStop_obj_znacky] = useState([]);

  const [objednat_data, setObjednat_data] = useState({
    head_datum: Date(),
    scrollPosition: {},
    head_datum_id: "",
  });
  const [kosikData, setKosikData] = useState([]);
  const [kosikButtony, setKosikButtony] = useState([]);
  const [hlavicka, setHlavicka] = useState([]);
  const [visible_kosik, setVisible_kosik] = useState(false);
  const [layoutText, setLayoutText] = useState("");
  const [layoutActive, setLayoutActive] = useState(false);
  const [kosikZmena, setKosikZmena] = useState(false);

  const [kopieData, setKopieData] = useState([]);
  const [kopieButtony, setKopieButtony] = useState([]);
  const [visible_kopia, setVisible_kopia] = useState(false);
  const [datum_kopie, setDatum_kopie] = React.useState(null);
  const [cis_objed_kopia, setCis_objed_kopia] = useState("");
  const [layoutText_kop, setLayoutText_kop] = useState("");
  const [layoutActive_kop, setLayoutActive_kop] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const [presunData, setPresunData] = useState([]);
  const [visible_presun, setVisible_presun] = useState(false);
  const [datum_presun, setDatum_presun] = React.useState(null);
  const [layoutText_presun, setLayoutText_presun] = useState("");
  const [layoutActive_presun, setLayoutActive_presun] = useState(false);
  const [rowSelectionModel_presun, setRowSelectionModel_presun] =
    React.useState([]);
  const [presunButtony, setPresunButtony] = useState([]);

  const connection = useSelector(signalRData);

  //const [objednavky, setObjednavky] = useState([]);

  const [rowGroupingModel, setRowGroupingModel] = React.useState(["skupina"]);
  const [coordinates, setCoordinates] = React.useState({
    rowIndex: 25,
    colIndex: 0,
  });
  const [datum_active, setDatum_active] = React.useState(null);
  const styling = {
    border: 0,
    "& .MuiDataGrid-cell, .MuiDataGrid-columnHeader": {
      padding: 0,
    },
    "& .MuiDataGrid-row Mui-hovered": {
      backgroundColor: "red",
    },
    "& .weekend6_help_nadpis": {
      padding: 1,
      backgroundColor: darkMode.value ? "#272b30" : "#f4f4f4",
    },
    "& .weekend6_help_cell": {
      backgroundColor: darkMode.value ? "#272b30" : "#f4f4f4",
    },

    "& .MuiDataGrid-overlayWrapperInner": {
      display: "none",
    },
    height: window.innerHeight > 500 ? window.innerHeight - 251 : 500,
    MuiTableRow: {
      root: {
        "&$selected": {
          backgroundColor: "yellow",
        },
      },
    },
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    letterSpacing: "normal",
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      color: darkMode.value ? "white" : "#666d74",
      borderRight: `1px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },

    "& .MuiSvgIcon-root": {
      color: "#a7acaf",
    },

    "& .MuiDataGrid-cell": {
      padding: 0,
      width: 70,
    },
    "& .MuiDataGrid-overlay": {
      display: "none",
    },

    "& .weekend6": {
      backgroundColor: darkMode.value ? "#272b30" : "#f4f4f4",
    },

    "& .MuiDataGrid-pinnedColumns": {
      backgroundColor: darkMode.value ? "#1a1d1f" : "#fcfcfc",
      borderRight: `0px solid ${darkMode.value ? "white" : "#e0e0e0"}`,
    },
    "& .weekend7": {
      backgroundColor: darkMode.value ? "#272b30" : "#f4f4f4",
      borderRight: `5px solid ${darkMode.value ? "white" : "#888888"}`,
    },
    width: "100%",
    justifyContent: "center",
  };

  const theme = createTheme({
    palette: {
      primary: { main: "#F1B600" },
    },
  });

  function doEvent(obj, event) {
    /* Created by David@Refoua.me */
    var event = new Event(event, { target: obj, bubbles: true });
    return obj ? obj.dispatchEvent(event) : false;
  }

  const handleFocus = (event) => {
    event.target.select();
    setFocusValue(event.target.value);
  };

  function isKosikEmpty() {
    return (
      allData.hlavicka.find(
        (column) => column.stav === 2 || column.stav === 4
      ) === undefined
    );
  }
  const preventSelection = (event) => {
    event.preventDefault();
  };

  async function nastav_balne_mn(
    nMnozstvo,
    balne_mn,
    des_mista,
    mj,
    head_datum,
    znacka,
    ScrollPosition,
    id,
    hlavicka
  ) {
    id = id.trim();
    if (balne_mn === 0 || nMnozstvo === 0) {
      setIsActive(true);
      setLoaderText("Zápis do objednávek");
      await zapis(
        head_datum,
        nMnozstvo,
        znacka,
        ScrollPosition,
        id.trim(),
        hlavicka
      );
      kosikButtony.map(async (button, index) => {
        var date = new Date(button.datum);
        if (date.getTime() === datum_active.getTime()) {
          //console.log("obj 247")
          await aktualizuj_kosik(button.datum, index);
        }
      });
      return;
    }
    var nNasobokDesMista = 10 ** des_mista;
    var nSize3_1 =
      Math.floor(
        (nMnozstvo * nNasobokDesMista) / (balne_mn * nNasobokDesMista)
      ) * balne_mn;
    var nSize3_2 = nSize3_1 + balne_mn;
    if (0 < nMnozstvo && nMnozstvo < balne_mn) {
      swal({
        content: {
          element: "div",
          attributes: {
            innerHTML:
              "Není možné objednat " +
              nMnozstvo +
              " " +
              mj +
              ". " +
              " Je třeba objednat v násobcích " +
              balne_mn
                .toFixed(des_mista)
                .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ") +
              "x " +
              mj +
              "." +
              "\n" +
              "Změnit objednávku na?",
          },
        },
        icon: "info",
        focus: false,
        buttons: { down: 0 + " " + mj, up: nSize3_2 + " " + mj },
      }).then(async (value) => {
        if (value === "up") {
          setIsActive(true);
          setLoaderText("Zápis do objednávek");
          try {
            var el = document.getElementById(id);
            el.value = nSize3_2;
            doEvent(el, "input");
          } catch (err) {}
          await zapis(
            head_datum,
            nSize3_2,
            znacka,
            ScrollPosition,
            id.trim(),
            hlavicka
          );
          kosikButtony.map(async (button, index) => {
            var date = new Date(button.datum);
            if (date.getTime() === datum_active.getTime()) {
              //console.log("obj 297")
              await aktualizuj_kosik(button.datum, index);
            }
          });
        } else {
          setIsActive(true);
          setLoaderText("Zápis do objednávek");
          try {
            var el = document.getElementById(id);
            el.value = 0;
            doEvent(el, "input");
          } catch (err) {}
          await zapis(
            head_datum,
            0,
            znacka,
            ScrollPosition,
            id.trim(),
            hlavicka
          );
          kosikButtony.map(async (button, index) => {
            var date = new Date(button.datum);
            if (date.getTime() === datum_active.getTime()) {
              //console.log("obj 313")
              await aktualizuj_kosik(button.datum, index);
            }
          });
        }
      });
    } else if (nSize3_1 % nMnozstvo === 0) {
      //console.log("tu som heeeeeeeeeeeeeeeeeeeeeeeeeeej", nSize3_1, nMnozstvo)
      setIsActive(true);
      setLoaderText("Zápis do objednávek");
      await zapis(
        head_datum,
        nMnozstvo,
        znacka,
        ScrollPosition,
        id.trim(),
        hlavicka
      );
      kosikButtony.map(async (button, index) => {
        var date = new Date(button.datum);
        if (date.getTime() === datum_active.getTime()) {
          //console.log("obj 326")
          await aktualizuj_kosik(button.datum, index);
        }
      });
    } else {
      swal({
        content: {
          element: "div",
          attributes: {
            innerHTML:
              "Není možné objednat " +
              nMnozstvo +
              " " +
              mj +
              ". " +
              " Je třeba objednat v násobcích " +
              balne_mn
                .toFixed(des_mista)
                .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ") +
              "x " +
              mj +
              "." +
              "\n" +
              "Změnit objednávku na?",
          },
        },
        icon: "info",
        buttons: { down: nSize3_1 + " " + mj, up: nSize3_2 + " " + mj },
      }).then(async (value) => {
        if (value === "up") {
          setIsActive(true);
          setLoaderText("Zápis do objednávek");
          try {
            var el = document.getElementById(id);
            el.value = nSize3_2;
            doEvent(el, "input");
          } catch (err) {}
          await zapis(
            head_datum,
            nSize3_2,
            znacka,
            ScrollPosition,
            id.trim(),
            hlavicka
          );
          kosikButtony.map(async (button, index) => {
            var date = new Date(button.datum);
            if (date.getTime() === datum_active.getTime()) {
              //console.log("obj 367")
              await aktualizuj_kosik(button.datum, index);
            }
          });
        } else {
          setIsActive(true);
          setLoaderText("Zápis do objednávek");
          try {
            var el = document.getElementById(id);
            el.value = nSize3_1;
            doEvent(el, "input");
          } catch (err) {}
          await zapis(
            head_datum,
            nSize3_1,
            znacka,
            ScrollPosition,
            id.trim(),
            hlavicka
          );
          kosikButtony.map(async (button, index) => {
            var date = new Date(button.datum);
            if (date.getTime() === datum_active.getTime()) {
              //console.log("obj 390")
              await aktualizuj_kosik(button.datum, index);
            }
          });
        }
      });
    }
  }

  function daj_hlavicku(stav, data, head_datum, exist_block) {
    switch (stav) {
      case 0:
        return <></>;

      case 3:
        return (
          <button className={styles.hotovo_special}>
            <i class="fa fa-check"></i>
          </button>
        );

      case 2:
        return (
          <button
            onClick={async () => {
              var scrollPosition = apiRef.current.getScrollPosition();
              //console.log(head_datum, scrollPosition, head.datum);

              setObjednat_data({
                ...objednat_data,
                scrollPosition: scrollPosition,
              });
              setLayoutActive(true);

              setVisible_kosik(true);

              setLayoutText(
                "Načítám na den " +
                  moment(head_datum, "ddd MMM DD YYYY HH:mm:ss").format(
                    "D.M.YYYY"
                  )
              );

              await nacitaj_kosik(head_datum);

              setHlavicka(data.hlavicka);

              // swal({
              //   title: "Chcete odeslat objednávku?",
              //   icon: "success",
              //   buttons: ["Ne ", "Ano"],
              //   //dangerMode: true,
              // }).then(async (willShow) => {
              //   if (willShow) {
              //     setIsActive(true);
              //     var date = new Date(head_datum)
              //       .toLocaleString("cs-CZ")
              //       .slice(0, 12);
              //     setLoaderText("Odesílám objednavku na " + date);
              //     objednat(head_datum, scrollPosition, head.datum);
              //   }
              // });
            }}
            className={styles.nova}
          >
            {exist_block ? "Nová +" : "Nová"}
          </button>
        );
      case 4:
        return (
          <button
            onClick={async () => {
              var scrollPosition = apiRef.current.getScrollPosition();
              //console.log(head_datum, scrollPosition, head.datum);

              setObjednat_data({
                ...objednat_data,
                scrollPosition: scrollPosition,
              });
              setLayoutActive(true);

              setVisible_kosik(true);

              setLayoutText(
                "Načítám na den " +
                  moment(head_datum, "ddd MMM DD YYYY HH:mm:ss").format(
                    "D.M.YYYY"
                  )
              );

              await nacitaj_kosik(head_datum);

              setHlavicka(data.hlavicka);

              // swal({
              //   title: "Chcete odeslat objednávku?",
              //   icon: "success",
              //   buttons: ["Ne ", "Ano"],
              //   //dangerMode: true,
              // }).then(async (willShow) => {
              //   if (willShow) {
              //     setIsActive(true);
              //     var date = new Date(head_datum)
              //       .toLocaleString("cs-CZ")
              //       .slice(0, 12);
              //     setLoaderText("Odesílám objednavku na " + date);
              //     objednat(head_datum, scrollPosition, head.datum);
              //   }
              // });
            }}
            className={styles.nova}
          >
            Změna
          </button>
        );
      case 1:
        return <button className={styles.hotovo}>Objednaná</button>;

      case 5:
        return <button className={styles.vyrizuje_se}>Vyřizuje se</button>;
    }
  }

  async function nacitaj_kosik(datum = null, cis_objed = null) {
    await axios
      .post(
        nacitajKosikObjOstatneUrl,
        {
          datum: datum === null ? null : new Date(datum).toISOString(),
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          ico:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
          cis_objed: cis_objed,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(async function (response) {
        if (response.data === "") {
          setVisible_kosik(false);
          setLayoutActive(false);
          return;
        }
        //console.log(response, new Date(response.data.datum));
        var scrollPosition = apiRef.current.getScrollPosition();
        if (datum === null) {
          setObjednat_data({
            head_datum: new Date(response.data.datum),
            scrollPosition: scrollPosition,
            head_datum_id: response.data.datum,
          });
          setDatum_active(new Date(response.data.datum));
          setKosikData(
            await predspracuj_data_v_kosiku(
              response.data.allRows.riadky,
              response.data.data,
              new Date(response.data.datum)
            )
          );
          setStop_obj_znacky(
            vyber_stop_obj_znacky(
              response.data.allRows.riadky,
              new Date(response.data.datum)
            )
          );
        } else {
          setObjednat_data({
            head_datum: datum,
            scrollPosition: scrollPosition,
            head_datum_id: moment(datum, "ddd MMM DD YYYY HH:mm:ss").format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
          });
          setDatum_active(datum);
          setKosikData(
            await predspracuj_data_v_kosiku(
              response.data.allRows.riadky,
              response.data.data,
              datum
            )
          );
          setStop_obj_znacky(
            vyber_stop_obj_znacky(response.data.allRows.riadky, datum)
          );
        }
        setKosikButtony(response.data.tlacidla);
        setKosikZmena(response.data.zmena);
        setLayoutActive(false);
        const element = document.getElementById(response.data.id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function nacitaj_kopie(datum, cis_objed = null) {
    await axios
      .post(
        kopiaObjednavkyUrl,
        {
          datum: new Date(datum).toISOString(),
          cis_objed: cis_objed,
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          ico:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        setCis_objed_kopia(response.data.cis_objed);
        setVisible_kopia(true);
        setKopieData(response.data.data);
        setKopieButtony(response.data.tlacidla);
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function nacitaj_plozky_na_presun(datum) {
    await axios
      .post(
        presunPoloziekObjednavkyUrl,
        {
          datum: new Date(datum).toISOString(),
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          ico:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        setVisible_presun(true);
        setPresunData(response.data.data);
        setPresunButtony(response.data.buttony);
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }
  async function aktualizuj_kosik(datum, pozicia, act = false) {
    console.log("aktualizuj_kosik", act, pozicia);
    await axios
      .post(
        nacitajKosikObjOstatneUrl,
        {
          datum: datum === null ? null : new Date(datum).toISOString(),
          pozicia: pozicia,
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          ico:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
          cis_objed: null,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(async function (response) {
        //console.log("resoinse", response);
        if (response.data === "") {
          setVisible_kosik(false);
          setLayoutActive(false);
          return;
        }
        var scrollPosition = apiRef.current.getScrollPosition();

        setObjednat_data({
          head_datum: new Date(response.data.datum),
          scrollPosition: scrollPosition,
          head_datum_id: moment(
            new Date(response.data.datum),
            "ddd MMM DD YYYY HH:mm:ss"
          ).format("YYYY-MM-DDTHH:mm:ss"),
        });
        setDatum_active(new Date(response.data.datum));

        //setVisible_kosik(true);
        if (act) {
          setLayoutActive(act);
        }

        setKosikData(
          await predspracuj_data_v_kosiku(
            response.data.allRows.riadky,
            response.data.data,
            new Date(response.data.datum)
          )
        );
        setStop_obj_znacky(
          vyber_stop_obj_znacky(
            response.data.allRows.riadky,
            new Date(response.data.datum)
          )
        );
        setKosikButtony(response.data.tlacidla);
        setKosikZmena(response.data.zmena);
        if (!act) {
          setLayoutActive(act);
        }
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  function vyber_stop_obj_znacky(dataObj, datum) {
    let znacky = [];
    for (var row_ of dataObj) {
      for (var bunka of row_.stlpce) {
        if (new Date(bunka.datum).getTime() === datum.setHours(0, 0, 0, 0)) {
          if (bunka.stop_obj) {
            znacky.push(bunka.znacka.trim());
          }
          break;
        }
      }
    }
    return znacky;
  }

  function existuje_nahrada(dataObj, znacka) {
    for (var row_ of dataObj) {
      if (row_.znacka.trim() === znacka.trim() && row_.nahrada.trim() !== "") {
        return true;
      }
    }
    return false;
  }

  function predspracuj_data_v_kosiku(dataObj, data, datum) {
    var stop_obj_znacky = vyber_stop_obj_znacky(dataObj, datum);
    //console.log(objednavky_typERP_Odata.allRows);
    var newData = data.map((row) => {
      var newRow = { ...row, stav_stop_obj: 0, typ_polozky: "" };
      if (
        stop_obj_znacky.includes(row.znacka.trim()) &&
        existuje_nahrada(dataObj, row.znacka.trim())
      ) {
        newRow.stav_stop_obj = 1;
        newRow.typ_polozky = "Nedostupné položky";
      } else if (
        stop_obj_znacky.includes(row.znacka.trim()) &&
        !existuje_nahrada(dataObj, row.znacka.trim())
      ) {
        newRow.stav_stop_obj = 2;
        newRow.typ_polozky = "Nedostupné položky";
      } else {
        newRow.stav_stop_obj = 0;
        newRow.typ_polozky = "Dostupné položky";
      }
      return newRow;
    });
    //console.log(newData);
    return newData;
  }

  window.addEventListener("popstate", function () {
    if (
      (connection.connectionObjednavky !== undefined ||
        connection.connectionObjednavky !== null) &&
      connection.connectionObjednavky
    ) {
      try {
        connection.connectionObjednavky.stop();
      } catch (e) {
        console.log(e);
      }
    }
  });

  useEffect(() => {
    dispatch(
      search({ data: { ...searchdata, ["searchObjednavky_typERP_O"]: "" } })
    );
    dispatch(pickerObjednavky_typERP_O({ data: "Všechno" }));
    setLoading(true);
    result("", null);
  }, []);

  useEffect(() => {
    if (
      (connection.connectionObjednavky !== undefined ||
        connection.connectionObjednavky !== null) &&
      connection.connectionObjednavky
    ) {
      try {
        connection.connectionObjednavky.stop();
      } catch (e) {
        console.log(e);
      }
    }
  }, [HubConnectionState.Reconnecting]);

  useEffect(() => {
    //removeAllConn();
    const connect = new HubConnectionBuilder()
      .withUrl(
        notifikaciaUrl +
          "?username=" +
          (logindata.UserData === undefined ? "" : logindata.UserData.username)
      )
      .withAutomaticReconnect()
      .build();
    dispatch(setConnectionObjednavky(connect));
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (connection.connectionObjednavky) {
      try {
        console.log("hub notification start");
        connection.connectionObjednavky
          .start()
          .then(() => {
            //ziskame notificationID ( kvoli update)
            connection.connectionObjednavky
              .invoke("GetConnectionId")
              .then(function (connectionID) {
                dispatch(setConnectionObjednavkyID(connectionID));
                //console.log("connectionID", connectionID);
              })
              .catch((error) => console.log(error));

            connection.connectionObjednavky.on(
              "ReceiveMessage",
              async (message) => {
                if (message.typ === "OBJEDNAVKY_REFRESH") {
                  console.log("OBJEDNAVKY_REFRESH");
                  result("WhatEver");
                } else if (message.typ === "OBJEDNAVKY_REFRESH_ZAPIS") {
                  console.log("OBJEDNAVKY_REFRESH_ZAPIS");

                  try {
                    var el = document.getElementById(message.data.id.trim());
                    var user_zadal =
                      logindata.UserData.vybrane_stredisko !== undefined
                        ? logindata.UserData.vybrane_stredisko.kod
                        : logindata.UserData.ico;
                    var user_pocuva =
                      message.data.stredisko !== undefined
                        ? message.data.stredisko
                        : message.data.ico;
                    //console.log(user_zadal, user_pocuva);
                    if (user_zadal === user_pocuva) {
                      await result("WhatEver", message.data.datum_filtra);
                      el.value =
                        message.data.mnozstvo !== undefined
                          ? message.data.mnozstvo
                          : "";
                      doEvent(el, "input");
                    }
                  } catch (error) {
                    //console.log(error);
                  }
                } else if (message.typ === "OBJEDNAVKY_REFRESH_ZAPIS_KOPIA") {
                  console.log("OBJEDNAVKY_REFRESH_ZAPIS_KOPIA");
                  //console.log(message);

                  var user_zadal =
                    logindata.UserData.vybrane_stredisko !== undefined
                      ? logindata.UserData.vybrane_stredisko.kod
                      : logindata.UserData.ico;

                  var user_pocuva =
                    message.data.stredisko !== undefined
                      ? message.data.stredisko
                      : message.data.ico;

                  var datum_for_id = moment(
                    new Date(message.data.datum),
                    "ddd MMM DD YYYY HH:mm:ss"
                  ).format("YYYY-MM-DDTHH:mm:ss");

                  if (user_zadal === user_pocuva) {
                    await result("WhatEver", message.data.datum_filtra);
                    message.data.data.riadky.map(async (riadok) => {
                      if (riadok !== null) {
                        var bunka = riadok.stlpce.find((x) => {
                          return (
                            new Date(x.datum).getTime() ===
                            new Date(message.data.datum).getTime()
                          );
                        });
                        if (
                          bunka.bunky[0].mnozstvo === undefined &&
                          document.getElementById(
                            datum_for_id + bunka.znacka.trim()
                          ) !== undefined
                        ) {
                          try {
                            var el = document.getElementById(
                              datum_for_id + bunka.znacka.trim()
                            );
                            el.value = "";
                            doEvent(el, "input");
                          } catch (error) {
                            //console.log(error);
                          }
                        } else if (
                          bunka.bunky[0].mnozstvo !== undefined &&
                          document.getElementById(
                            datum_for_id + bunka.znacka.trim()
                          ) !== undefined
                        ) {
                          try {
                            var el = document.getElementById(
                              datum_for_id + bunka.znacka.trim()
                            );
                            el.value = bunka.bunky[0].mnozstvo;
                            doEvent(el, "input");
                          } catch (error) {
                            //console.log(error);
                          }
                        }
                      }
                    });
                  }
                } else if (message.typ === "OBJEDNAVKY_REFRESH_ZAPIS_PRESUN") {
                  console.log("OBJEDNAVKY_REFRESH_ZAPIS_PRESUN");
                  console.log(message);

                  var user_zadal =
                    logindata.UserData.vybrane_stredisko !== undefined
                      ? logindata.UserData.vybrane_stredisko.kod
                      : logindata.UserData.ico;

                  var user_pocuva =
                    message.data.stredisko !== undefined
                      ? message.data.stredisko
                      : message.data.ico;

                  //new
                  var datum_for_id = moment(
                    new Date(message.data.datum),
                    "ddd MMM DD YYYY HH:mm:ss"
                  ).format("YYYY-MM-DDTHH:mm:ss");

                  //old
                  var datum_for_id_old = moment(
                    new Date(message.data.datum_old),
                    "ddd MMM DD YYYY HH:mm:ss"
                  ).format("YYYY-MM-DDTHH:mm:ss");

                  //new
                  if (user_zadal === user_pocuva) {
                    await result("WhatEver", message.data.datum_filtra);
                    //new
                    message.data.data.riadky.map(async (riadok) => {
                      if (riadok !== null) {
                        var bunka = riadok.stlpce.find((x) => {
                          return (
                            new Date(x.datum).getTime() ===
                            new Date(message.data.datum).getTime()
                          );
                        });
                        if (
                          bunka.bunky[0].mnozstvo === undefined &&
                          document.getElementById(
                            datum_for_id + bunka.znacka.trim()
                          ) !== undefined
                        ) {
                          try {
                            var el = document.getElementById(
                              datum_for_id + bunka.znacka.trim()
                            );
                            el.value = "";
                            doEvent(el, "input");
                          } catch (error) {
                            //console.log(error);
                          }
                        } else if (
                          bunka.bunky[0].mnozstvo !== undefined &&
                          document.getElementById(
                            datum_for_id + bunka.znacka.trim()
                          ) !== undefined
                        ) {
                          try {
                            var el = document.getElementById(
                              datum_for_id + bunka.znacka.trim()
                            );
                            el.value = bunka.bunky[0].mnozstvo;
                            doEvent(el, "input");
                          } catch (error) {
                            //console.log(error);
                          }
                        }
                      }
                    });
                    //old
                    message.data.data.riadky.map(async (riadok) => {
                      if (riadok !== null) {
                        var bunka = riadok.stlpce.find((x) => {
                          return (
                            new Date(x.datum).getTime() ===
                            new Date(message.data.datum_old).getTime()
                          );
                        });
                        if (
                          bunka.bunky[0].mnozstvo === undefined &&
                          document.getElementById(
                            datum_for_id_old + bunka.znacka.trim()
                          ) !== undefined
                        ) {
                          try {
                            var el = document.getElementById(
                              datum_for_id_old + bunka.znacka.trim()
                            );
                            el.value = "";
                            doEvent(el, "input");
                          } catch (error) {
                            //console.log(error);
                          }
                        } else if (
                          bunka.bunky[0].mnozstvo !== undefined &&
                          document.getElementById(
                            datum_for_id_old + bunka.znacka.trim()
                          ) !== undefined
                        ) {
                          try {
                            var el = document.getElementById(
                              datum_for_id_old + bunka.znacka.trim()
                            );
                            el.value = bunka.bunky[0].mnozstvo;
                            doEvent(el, "input");
                          } catch (error) {
                            //console.log(error);
                          }
                        }
                      }
                    });
                  }
                } else if (message.typ === "OBJEDNAVKY_REFRESH_OBJEDNAT") {
                  console.log("OBJEDNAVKY_REFRESH_OBJEDNAT");
                  var user_zadal =
                    logindata.UserData.vybrane_stredisko !== undefined
                      ? logindata.UserData.vybrane_stredisko.kod
                      : logindata.UserData.ico;
                  var user_pocuva =
                    message.data.stredisko !== undefined
                      ? message.data.stredisko
                      : message.data.ico;

                  if (user_zadal === user_pocuva) {
                    await result("WhatEver");
                    message.data.data.riadky.map((riadok) => {
                      if (riadok !== null) {
                        var bunka = riadok.stlpce.find((x) => {
                          return (
                            new Date(x.datum).toISOString() ===
                            new Date(message.data.datum).toISOString()
                          );
                        });
                        if (
                          (bunka.bunky[0].mnozstvo === undefined ||
                            bunka.bunky[0].mnozstvo === null) &&
                          document.getElementById(
                            message.data.datum_for_id + bunka.znacka.trim()
                          ) !== undefined
                        ) {
                          //console.log(riadok, bunka);
                          try {
                            var el = document.getElementById(
                              message.data.datum_for_id + bunka.znacka.trim()
                            );
                            el.value = null;
                            doEvent(el, "input");
                          } catch (error) {
                            //console.log(error);
                          }
                        } else {
                          try {
                            var el = document.getElementById(
                              message.data.datum_for_id + bunka.znacka.trim()
                            );
                            el.value = bunka.bunky[0].mnozstvo;
                            doEvent(el, "input");
                          } catch (error) {
                            //console.log(error);
                          }
                        }
                      }
                    });
                    console.log(visible_kosik);
                    if (visible_kosik) {
                      console.log("dostal som sa k aktualizaci kosika");
                      await aktualizuj_kosik(null, 0);
                    }
                  }
                }
              }
            );
          })
          .catch((error) => console.log(error));
      } catch (error) {
        console.log(error);
      }
    }
  }, [connection.connectionObjednavky]);

  useEffect(() => {
    if (!loading) {
      filter(
        objednavky_typERP_Odata.allRows,
        null,
        searchdata.searchObjednavky_typERP_O,
        pickerObjednavky_typERP_Odata
      );
      if (allData.riadky !== undefined) createColumns(allData);
    }
  }, [searchdata.searchObjednavky_typERP_O]);

  useEffect(() => {
    if (!loading) {
      filter(
        objednavky_typERP_Odata.allRows,
        null,
        searchdata.searchObjednavky_typERP_O,
        pickerObjednavky_typERP_Odata
      );
      if (allData.riadky !== undefined) createColumns(allData);
    }
  }, [pickerObjednavky_typERP_Odata]);

  useEffect(() => {
    if (!loading) {
      filter(
        objednavky_typERP_Odata.allRows,
        null,
        searchdata.searchObjednavky_typERP_O,
        pickerObjednavky_typERP_Odata
      );
      if (allData.riadky !== undefined) createColumns(allData);
    }
  }, [pickerTagyObjednavky_typERP_Odata.tagy]);

  async function result(priznak = "", datum_filtra = null) {
    //console.log("result");

    await axios
      .post(
        objednavky_typERP_OUrl,
        {
          datum_filtra:
            datum_filtra !== null ? new Date(datum_filtra).toISOString() : null,
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          ico:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        console.log(
          "totot potrebujem vidiet",
          searchdata.searchObjednavky_typERP_O,
          response.data
        );

        let tagy = response.data.tagy_ostatne.map((row) => ({
          ...row,
          vybrane: false,
        }));

        dispatch(objednavky_typERP_OAllRows(response.data.riadky));
        if (priznak === "") filter(response.data.riadky, tagy, "", "Všechno");
        else
          filter(
            response.data.riadky,
            null,
            searchdata.searchObjednavky_typERP_O,
            pickerObjednavky_typERP_Odata
          );
        createColumns(response.data);
        setAllData(response.data);
        if (priznak === "") dispatch(pickerTagyObjednavky_typERP_O(tagy));
        let skupiny = [];
        skupiny.push("Všechno");
        response.data.riadky.map((row) => {
          if (row.skupina !== skupiny[skupiny.length - 1]) {
            skupiny.push(row.skupina);
          }
        });
        setOptions(skupiny);
        setLoading(false);

        if (priznak === "") {
          apiRef.current?.scroll({
            top: 0,
            left: 1960,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function zapis_presun(
    datum,
    polozky,
    datum_old,
    scrollPosition,
    hlavicka
  ) {
    //console.log(connection.connectionObjednavkyID);
    var datum_filtra = null;
    hlavicka.map((head) => {
      if (head.activeFilter) {
        datum_filtra = new Date(head.datum);
      }
    });
    console.log(datum, datum_old);
    await axios
      .post(
        presunPoloziekObjednavkyZapisUrl,
        {
          datum: new Date(datum).toISOString(),
          datum_old: new Date(datum_old).toISOString(),
          polozky: polozky,
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          ico:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
          username: logindata.UserData.username,
          connectionID: connection.connectionObjednavkyID,
          scrollPosition: JSON.stringify(scrollPosition),
          datum_filtra: datum_filtra,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        console.log(response);
        let tagy = response.data.tagy_ostatne;
        dispatch(objednavky_typERP_OAllRows(response.data.data.riadky));
        filter(
          response.data.data.riadky,
          null,
          searchdata.searchObjednavky_typERP_O,
          pickerObjednavky_typERP_Odata
        );

        //new
        var datum_for_id = moment(
          new Date(response.data.datum),
          "ddd MMM DD YYYY HH:mm:ss"
        ).format("YYYY-MM-DDTHH:mm:ss");
        //console.log(datum_for_id)

        //old
        var datum_for_id_old = moment(
          new Date(response.data.datum_old),
          "ddd MMM DD YYYY HH:mm:ss"
        ).format("YYYY-MM-DDTHH:mm:ss");
        //console.log(datum_for_id)

        response.data.data.riadky.map((riadok) => {
          //new
          if (riadok !== null) {
            var bunka = riadok.stlpce.find((x) => {
              return (
                new Date(x.datum).getTime() ===
                new Date(response.data.datum).getTime()
              );
            });
            if (
              bunka.bunky[0].mnozstvo === undefined &&
              document.getElementById(datum_for_id + bunka.znacka.trim()) !==
                undefined
            ) {
              try {
                var el = document.getElementById(
                  datum_for_id + bunka.znacka.trim()
                );
                //console.log(el);
                el.value = "";
                doEvent(el, "input");
              } catch (error) {
                //console.log(error);
              }
            } else if (
              bunka.bunky[0].mnozstvo !== undefined &&
              document.getElementById(datum_for_id + bunka.znacka.trim()) !==
                undefined
            ) {
              try {
                var el = document.getElementById(
                  datum_for_id + bunka.znacka.trim()
                );
                //console.log(el);
                el.value = bunka.bunky[0].mnozstvo;
                doEvent(el, "input");
              } catch (error) {
                //console.log(error);
              }
            }
          }

          //old
          if (riadok !== null) {
            var bunka = riadok.stlpce.find((x) => {
              return (
                new Date(x.datum).getTime() ===
                new Date(response.data.datum_old).getTime()
              );
            });
            if (
              bunka.bunky[0].mnozstvo === undefined &&
              document.getElementById(
                datum_for_id_old + bunka.znacka.trim()
              ) !== undefined
            ) {
              try {
                var el = document.getElementById(
                  datum_for_id_old + bunka.znacka.trim()
                );
                //console.log(el);
                el.value = "";
                doEvent(el, "input");
              } catch (error) {
                //console.log(error);
              }
            } else if (
              bunka.bunky[0].mnozstvo !== undefined &&
              document.getElementById(
                datum_for_id_old + bunka.znacka.trim()
              ) !== undefined
            ) {
              try {
                var el = document.getElementById(
                  datum_for_id_old + bunka.znacka.trim()
                );
                //console.log(el);
                el.value = bunka.bunky[0].mnozstvo;
                doEvent(el, "input");
              } catch (error) {
                //console.log(error);
              }
            }
          }
        });
        createColumns(response.data.data);
        setAllData(response.data.data);
        setLayoutActive_presun(false);
        setIsActive(false);
        setVisible_presun(false);
        apiRef.current.scroll({
          top: scrollPosition.top,
          left: scrollPosition.left,
        });
      })
      .catch(function (error) {
        console.log(error);
        if (error.response !== undefined && error.response.status === 400) {
          swal(
            "Chyba!",
            error.response.data.err_msg
              ? error.response.data.err_msg
              : "Neznama chyba na polozke XY",
            "error"
          );
        } else if (
          error.response !== undefined &&
          error.response.status === 401
        ) {
          navigate.push("/");
          //dispatch(login({ data: {} }));
          window.sessionStorage.setItem("logindata", JSON.stringify({}));
        } else if (
          error.response !== undefined &&
          error.response.status > 401 &&
          error.response.status < 500
        ) {
          swal(
            "Chyba!",
            "Nastava chyba v komunikaci se službou ERP. Zkontrolujte internetové připojení.",
            "error"
          );
        } else {
          swal(
            "Chyba!",
            " Nastala chyba na serveri ERP, zkuste to později.",
            "error"
          );
        }
        setLayoutActive_presun(false);
        setIsActive(false);
        // if (mnozstvo < 1) {
        //   document.getElementById(id).value = focusValue;
        // }
        apiRef.current.scroll({
          top: scrollPosition.top,
          left: scrollPosition.left,
        });
      });
  }

  async function zapis_kopie(
    datum,
    polozky,
    cis_objed,
    scrollPosition,
    hlavicka
  ) {
    //console.log(connection.connectionObjednavkyID);
    var datum_filtra = null;
    hlavicka.map((head) => {
      if (head.activeFilter) {
        datum_filtra = new Date(head.datum);
      }
    });

    await axios
      .post(
        kopiaZapisObjednavkyUrl,
        {
          datum: new Date(datum).toISOString(),
          polozky: polozky,
          cis_objed: cis_objed,
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          ico:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
          username: logindata.UserData.username,
          connectionID: connection.connectionObjednavkyID,
          scrollPosition: JSON.stringify(scrollPosition),
          datum_filtra: datum_filtra,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        console.log(response);
        let tagy = response.data.tagy_ostatne;
        dispatch(objednavky_typERP_OAllRows(response.data.data.riadky));
        filter(
          response.data.data.riadky,
          null,
          searchdata.searchObjednavky_typERP_O,
          pickerObjednavky_typERP_Odata
        );
        createColumns(response.data.data);
        setAllData(response.data.data);
        setLayoutActive_kop(false);
        setIsActive(false);

        var datum_for_id = moment(
          new Date(response.data.datum),
          "ddd MMM DD YYYY HH:mm:ss"
        ).format("YYYY-MM-DDTHH:mm:ss");
        //console.log(datum_for_id)

        response.data.data.riadky.map((riadok) => {
          if (riadok !== null) {
            var bunka = riadok.stlpce.find((x) => {
              return (
                new Date(x.datum).getTime() ===
                new Date(response.data.datum).getTime()
              );
            });
            if (
              bunka.bunky[0].mnozstvo === undefined &&
              document.getElementById(datum_for_id + bunka.znacka.trim()) !==
                undefined
            ) {
              try {
                var el = document.getElementById(
                  datum_for_id + bunka.znacka.trim()
                );
                //console.log(el);
                el.value = "";
                doEvent(el, "input");
              } catch (error) {
                //console.log(error);
              }
            } else if (
              bunka.bunky[0].mnozstvo !== undefined &&
              document.getElementById(datum_for_id + bunka.znacka.trim()) !==
                undefined
            ) {
              try {
                var el = document.getElementById(
                  datum_for_id + bunka.znacka.trim()
                );
                //console.log(el);
                el.value = bunka.bunky[0].mnozstvo;
                doEvent(el, "input");
              } catch (error) {
                //console.log(error);
              }
            }
          }
        });

        setVisible_kopia(false);
        apiRef.current.scroll({
          top: scrollPosition.top,
          left: scrollPosition.left,
        });
      })
      .catch(function (error) {
        console.log(error);
        if (error.response !== undefined && error.response.status === 400) {
          swal(
            "Chyba!",
            error.response.data.err_msg
              ? error.response.data.err_msg
              : "Neznama chyba na polozke XY",
            "error"
          );
        } else if (
          error.response !== undefined &&
          error.response.status === 401
        ) {
          navigate.push("/");
          //dispatch(login({ data: {} }));
          window.sessionStorage.setItem("logindata", JSON.stringify({}));
        } else if (
          error.response !== undefined &&
          error.response.status > 401 &&
          error.response.status < 500
        ) {
          swal(
            "Chyba!",
            "Nastava chyba v komunikaci se službou ERP. Zkontrolujte internetové připojení.",
            "error"
          );
        } else {
          swal(
            "Chyba!",
            " Nastala chyba na serveri ERP, zkuste to později.",
            "error"
          );
        }
        setLayoutActive_kop(false);
        setIsActive(false);
        // if (mnozstvo < 1) {
        //   document.getElementById(id).value = focusValue;
        // }
        apiRef.current.scroll({
          top: scrollPosition.top,
          left: scrollPosition.left,
        });
      });
  }

  async function zapis(datum, mnozstvo, znacka, scrollPosition, id, hlavicka) {
    //console.log(connection.connectionObjednavkyID);
    var datum_filtra = null;
    hlavicka.map((head) => {
      if (head.activeFilter) {
        datum_filtra = new Date(head.datum);
      }
    });

    await axios
      .post(
        objednavky_typERP_O_zapisUrl,
        {
          datum: new Date(datum).toISOString(),
          znacka: znacka,
          mnozstvo: mnozstvo,
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          ico:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
          username: logindata.UserData.username,
          connectionID: connection.connectionObjednavkyID,
          scrollPosition: JSON.stringify(scrollPosition),
          id: id,
          datum_filtra: datum_filtra,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        let tagy = response.data.tagy_ostatne;
        dispatch(objednavky_typERP_OAllRows(response.data.data.riadky));
        filter(
          response.data.data.riadky,
          null,
          searchdata.searchObjednavky_typERP_O,
          pickerObjednavky_typERP_Odata
        );
        createColumns(response.data.data);
        setAllData(response.data.data);

        try {
          if (response.data.mnozstvo === undefined) {
            document.getElementById(id).value = "";
          } else {
            document.getElementById(id).value = response.data.mnozstvo;
          }
        } catch (err) {
          //console.log(err)
        }

        setIsActive(false);
        apiRef.current.scroll({
          top: scrollPosition.top,
          left: scrollPosition.left,
        });
      })
      .catch(function (error) {
        console.log(error);

        if (error.response !== undefined && error.response.status === 401) {
          navigate.push("/");
          //dispatch(login({ data: {} }));
          window.sessionStorage.setItem("logindata", JSON.stringify({}));
        }
        if (error.response !== undefined) {
          dispatch(objednavky_typERP_OAllRows(error.response.data.data.riadky));
          filter(
            error.response.data.data.riadky,
            null,
            searchdata.searchObjednavky_typERP_O,
            pickerObjednavky_typERP_Odata
          );
          createColumns(error.response.data.data);
          setAllData(error.response.data.data);
          //nastavenie bunky na pôcodnú hodnotu
          var riadok = error.response.data.data.riadky.find(
            (x) => x.znacka.trim() === znacka.trim()
          );
          //console.log("riadok", riadok, new Date(datum));
          if (riadok !== null) {
            var bunka = riadok.stlpce.find((x) => {
              return (
                new Date(x.datum).toISOString() ===
                new Date(datum).toISOString()
              );
            });
            //console.log("bunka", bunka);
            if (bunka.bunky[0].mnozstvo === undefined) {
              document.getElementById(id).value = null;
            } else {
              document.getElementById(id).value = bunka.bunky[0].mnozstvo;
            }
          }
        }
        if (
          error.response.data.info_msg !== undefined &&
          error.response.data.info_msg !== ""
        ) {
          swal({
            content: {
              element: "div",
              attributes: {
                innerHTML: error.response.data.info_msg,
              },
            },
            icon: "info",
            title: "Upozornění!",
          });
          setIsActive(false);
          // if (mnozstvo < 1) {
          //   document.getElementById(id).value = focusValue;
          // }
          apiRef.current.scroll({
            top: scrollPosition.top,
            left: scrollPosition.left,
          });
          return;
        }
        if (error.response !== undefined && error.response.status === 400) {
          swal(
            "Chyba!",
            error.response.data.err_msg
              ? error.response.data.err_msg
              : "Neznama chyba na polozke XY",
            "error"
          );
        } else if (
          error.response !== undefined &&
          error.response.status === 401
        ) {
          navigate.push("/");
          //dispatch(login({ data: {} }));
          window.sessionStorage.setItem("logindata", JSON.stringify({}));
        } else if (
          error.response !== undefined &&
          error.response.status > 401 &&
          error.response.status < 500
        ) {
          swal(
            "Chyba!",
            "Nastava chyba v komunikaci se službou ERP. Zkontrolujte internetové připojení.",
            "error"
          );
        } else {
          swal(
            "Chyba!",
            " Nastala chyba na serveri ERP, zkuste to později.",
            "error"
          );
        }
        setIsActive(false);
        // if (mnozstvo < 1) {
        //   document.getElementById(id).value = focusValue;
        // }
        apiRef.current.scroll({
          top: scrollPosition.top,
          left: scrollPosition.left,
        });
      });
  }

  async function objednat(
    datum,
    scrollPosition,
    datum_for_id,
    hlavicka,
    priznak = null
  ) {
    console.log("robim objednavku");

    var datum_filtra = null;
    hlavicka.map((head) => {
      if (head.activeFilter) {
        datum_filtra = new Date(head.datum);
      }
    });

    var cis_objed_activ = "";
    kosikButtony.map(async (button, index) => {
      var date = new Date(button.datum);
      if (date.getTime() === datum_active.getTime()) {
        cis_objed_activ = button.cis_objed;
      }
    });

    await axios
      .post(
        objednavky_typERP_O_objednavkaUrl,
        {
          datum: new Date(datum).toISOString(),
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          ico:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
          connectionID: connection.connectionObjednavkyID,
          //scrollPosition: JSON.stringify(scrollPosition),
          datum_for_id: datum_for_id,
          datum_filtra: datum_filtra,
          stara_objednavka: priznak,
          cis_objed: cis_objed_activ,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        //let tagy = response.data.tagy;
        if (response.data.html !== null) {
          dispatch(
            modal({
              data: {
                ...modaldata,
                ZobrazHtml: true,
                HtmlData: response.data.html,
                ZobrazHtml_print: response.data.print,
                ZobrazHtml_text: response.data.text,
              },
            })
          );
        }
        dispatch(objednavky_typERP_OAllRows(response.data.data.riadky));
        filter(
          response.data.data.riadky,
          null,
          searchdata.searchObjednavky_typERP_O,
          pickerObjednavky_typERP_Odata
        );
        createColumns(response.data.data);
        setAllData(response.data.data);
        setIsActive(false);
        apiRef.current.scroll({
          top: scrollPosition.top,
          left: scrollPosition.left,
        });

        response.data.data.riadky.map((riadok) => {
          if (riadok !== null) {
            var bunka = riadok.stlpce.find((x) => {
              return (
                new Date(x.datum).toISOString() ===
                new Date(datum).toISOString()
              );
            });

            if (
              bunka.bunky[0].mnozstvo === undefined &&
              document.getElementById(datum_for_id + bunka.znacka.trim()) !==
                undefined
            ) {
              try {
                var el = document.getElementById(
                  datum_for_id + bunka.znacka.trim()
                );
                //console.log(el);
                el.value = null;
                doEvent(el, "input");
              } catch (error) {
                //console.log(error);
              }
            }
          }
        });
      })
      .catch(function (error) {
        console.log(error);
        setIsActive(false);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  async function vynulovat_kosik(
    datum,
    scrollPosition,
    datum_for_id,
    hlavicka,
    cis_objed
  ) {
    //console.log("connectionObjednavkyID", connection.connectionObjednavkyID);

    var datum_filtra = null;
    hlavicka.map((head) => {
      if (head.activeFilter) {
        datum_filtra = new Date(head.datum);
      }
    });

    await axios
      .post(
        nulovanieKosikaUrl,
        {
          datum: new Date(datum).toISOString(),
          stredisko:
            logindata.UserData.vybrane_stredisko !== undefined
              ? logindata.UserData.vybrane_stredisko.kod
              : null,
          ico:
            logindata.UserData.ico !== undefined
              ? logindata.UserData.ico
              : null,
          connectionID: connection.connectionObjednavkyID,
          datum_filtra: datum_filtra,
          datum_for_id: datum_for_id,
          cis_objed: cis_objed,
        },
        {
          headers: { Authorization: "Bearer " + logindata.Token },
        }
      )
      .then(function (response) {
        dispatch(objednavky_typERP_OAllRows(response.data.data.riadky));
        filter(
          response.data.data.riadky,
          null,
          searchdata.searchObjednavky_typERP_O,
          pickerObjednavky_typERP_Odata
        );
        createColumns(response.data.data);
        setAllData(response.data.data);
        apiRef.current.scroll({
          top: scrollPosition.top,
          left: scrollPosition.left,
        });

        response.data.data.riadky.map((riadok) => {
          if (riadok !== null) {
            var bunka = riadok.stlpce.find((x) => {
              return (
                new Date(x.datum).toISOString() ===
                new Date(datum).toISOString()
              );
            });

            if (
              bunka.bunky[0].mnozstvo === undefined &&
              document.getElementById(datum_for_id + bunka.znacka.trim()) !==
                undefined
            ) {
              try {
                var el = document.getElementById(
                  datum_for_id + bunka.znacka.trim()
                );
                //console.log(el);
                el.value = null;
                doEvent(el, "input");
              } catch (error) {
                //console.log(error);
              }
            } else {
              try {
                var el = document.getElementById(
                  datum_for_id + bunka.znacka.trim()
                );
                el.value = bunka.bunky[0].mnozstvo;
                doEvent(el, "input");
              } catch (error) {
                //console.log(error);
              }
            }
          }
        });
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  function arraysEqualF(array1, array2) {
    try {
      let found = false;
      let array1_update = array1 === undefined ? [] : array1;
      array2.map((tag) => {
        if (tag.vybrane) found = true;
      });
      if (!found) {
        return true;
      }
      for (let i = 0; i < array1_update.length; i++) {
        found = false;
        for (let j = 0; j < array2.length; j++) {
          if (array1_update[i].id === array2[j].id && array2[j].vybrane) {
            found = true;
            break;
          }
        }
        if (found) {
          return true;
        }
      }
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  function filter(data, tagy = null, search, picker) {
    let newRows = [];
    if (picker === "Všechno") {
      data.map((row) => {
        //console.log(row, pickerTagyObjednavky_typERP_Odata.tagy)
        if (
          (String(remove_accents(row.nazov.toUpperCase())).includes(
            remove_accents(search.toUpperCase().trim())
          ) ||
            String(remove_accents(row.znacka.toUpperCase())).includes(
              remove_accents(search.toUpperCase().trim())
            )) &&
          arraysEqualF(
            row.tagy_ostatne,
            tagy !== null ? tagy : pickerTagyObjednavky_typERP_Odata.tagy
          )
        ) {
          newRows.push(row);
        }
      });
      //console.log("newRows", newRows);
      dispatch(objednavky_typERP_ORows(newRows));
      return;
    }

    data.map((row) => {
      if (
        (String(remove_accents(row.nazov.toUpperCase())).includes(
          remove_accents(search.toUpperCase().trim())
        ) ||
          String(remove_accents(row.znacka.toUpperCase())).includes(
            remove_accents(search.toUpperCase().trim())
          )) &&
        picker === row.skupina &&
        arraysEqualF(
          row.tagy_ostatne,
          tagy !== null ? tagy : pickerTagyObjednavky_typERP_Odata.tagy
        )
      ) {
        newRows.push(row);
      }
    });
    //console.log("newRows", newRows);
    dispatch(objednavky_typERP_ORows(newRows));
  }

  function createColumns(data) {
    //console.log("create comuns data", data);
    let newColumns = [];
    newColumns.push({
      field: "skupina",
      headerName: "Skupina",
      width: 180,
      groupable: true,
      sortable: false,
      draggable: false,
    });
    newColumns.push({
      field: "nazov",
      headerName: "Položky",
      width: 340,
      groupable: false,
      draggable: false,
      headerClassName: "weekend6",
      cellClassName: "weekend6",
      renderHeader: () => (
        <div className={styles.nazov}>
          <div className={styles.nazovTitle}>Položky</div>
          <div className={styles.pokladna}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              height="1.15em"
              width="1.15em"
            >
              <path d="M288 0c17.7 0 32 14.33 32 32v64c0 17.7-14.3 32-32 32h-80v32h216.1c32.5 0 59.4 23.1 64.1 54.4l22.7 149.7c.7 4.7 1.1 9.5 1.1 14.3V448c0 35.3-28.7 64-64 64H64c-35.35 0-64-28.7-64-64v-69.6c0-4.8.362-9.6 1.083-14.3L23.76 214.4C28.5 183.1 55.39 160 87.03 160h56.07v-32h-80c-16.77 0-32-14.3-32-32V32c0-17.67 15.23-32 32-32H288zM96 48c-8.84 0-16 7.16-16 16s7.16 16 16 16h160c8.8 0 16-7.16 16-16s-7.2-16-16-16H96zM80 448h352c8.8 0 16-7.2 16-16s-7.2-16-16-16H80c-8.84 0-16 7.2-16 16s7.16 16 16 16zm32-232c-13.25 0-24 10.7-24 24s10.75 24 24 24c13.3 0 24-10.7 24-24s-10.7-24-24-24zm96 48c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24zm-48 32c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm144-32c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24zm-48 32c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm144-32c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24zm-48 32c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24z" />
            </svg>
          </div>
        </div>
      ),
      renderCell: (params) => (
        <>
          {params.row.stlpce ? (
            <div className={styles.nazov_2}>
              <div className={styles.avatar}>
                <img src={"/images/content/bageta2.png"} alt="Avatar" />
              </div>
              <div className={styles.nazov_kod}>
                <div>{params.row.nazovgrid}</div>
                <div className={styles.znacka}>
                  <div className={styles.znackaText}>{params.row.znacka}</div>
                  <button
                    onClick={() => {
                      dispatch(
                        modal({
                          data: {
                            ...modaldata,
                            EditTagovPolozkyID: params.row.id,
                            EditTagovPolozky: true,
                            EditTagovPolozkyType: "O",
                          },
                        })
                      );
                    }}
                    className={styles.addButton}
                  >
                    <div className={styles.plus}>+</div>{" "}
                  </button>
                  <div className={styles.tagy}>
                    {params.row.tagy_ostatne === undefined
                      ? []
                      : params.row.tagy_ostatne.map((tag, index) => {
                          if (index <= 3) {
                            return (
                              <div
                                title={tag.text}
                                style={{
                                  color:
                                    tag.farba === "yellow" ? "black" : "white",
                                  width: 20,
                                  height: 20,
                                  borderRadius: 4,
                                  backgroundColor: tag.farba,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  marginLeft: 5,
                                }}
                              >
                                {tag.text !== undefined
                                  ? tag.text.slice(0, 1) + "."
                                  : ""}
                              </div>
                            );
                          }
                        })}
                  </div>
                </div>
              </div>
              <div className={styles.S_O}>
                <div>{params.row.mjInfo}</div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ),
    });
    data.hlavicka.map((head) => {
      console.log(head)
      var head_datum = new Date(head.datum);
      var today = new Date(new Date().setHours(0, 0, 0, 0));
      newColumns.push({
        field: head.datum,
        sortable: false,
        groupable: false,
        draggable: false,
        headerClassName:
          head.den === 6 || head.den == 7
            ? head.den === 6
              ? "weekend6"
              : "weekend7"
            : "week",
        cellClassName:
          head.den === 6 || head.den == 7
            ? head.den === 6
              ? "weekend6"
              : "weekend7"
            : "week",
        renderHeader: () => (
          <div className={head.zavozovy_den ? styles.headerTableObjDay : styles.headerTable} title={head.tooltip}>
            <div
              className={
                head_datum >= today
                  ? styles.headerTable_datum_today
                  : styles.headerTable_datum
              }
            >
              <div
                onClick={async () => {
                  setDatum_kopie(head_datum);
                  setDatum_presun(head_datum);

                  var scrollPosition = apiRef.current.getScrollPosition();
                  setObjednat_data({
                    ...objednat_data,
                    scrollPosition: scrollPosition,
                  });
                  setRowSelectionModel([]);
                  setRowSelectionModel_presun([]);
                  setHlavicka(data.hlavicka);
                  if (head.stav === 1 || head.stav === 3 || head.stav === 5) {
                    await nacitaj_kopie(head_datum);
                  }
                  if (head.stav === 2) {
                    await nacitaj_plozky_na_presun(head_datum);
                  }
                }}
                title={
                  head.stav === 1 || head.stav === 3 || head.stav === 5
                    ? "Duplikace již provedené objednávky."
                    : head.stav === 2
                    ? "Přesun rozpracované objednávky."
                    : ""
                }
                className={styles.headerFilterN}
              >
                {head.stav === 1 || head.stav === 3 || head.stav === 5 ? (
                  <i class="fa fa-copy"></i>
                ) : head.stav === 2 ? (
                  <i class="	fas fa-exchange-alt"></i>
                ) : (
                  <></>
                )}
              </div>
              <div>{head.s_datum}</div>
              <div
                onClick={() => {
                  //console.log(head_datum);
                  if (head.activeFilter) result("WhatEver");
                  else result("WhatEver", head_datum);
                }}
                title={
                  head.activeFilter
                    ? "Zrušit filtr."
                    : "Filtr položek, objednaných a přidaných do košíku."
                }
                className={
                  head.activeFilter
                    ? styles.headerFilterA
                    : styles.headerFilterN
                }
              >
                <i class="fa fa-filter"></i>
              </div>
            </div>
            <div title={head.title} className={styles.headerTable_datum}>
              {daj_hlavicku(head.stav, data, head_datum, head.exist_block)}
            </div>
          </div>
        ),
        width: 70,

        renderCell: (params) => (
          <>
            {/* <div>
              {params.row.stlpce &&
                params.row.stlpce.map((stlpec, index) => {
                  if (stlpec.datum === head.datum) {
                    return (
                      <div
                        key={index}
                        className={
                          head_datum < today ? styles.cell_dis : styles.cell
                        }
                        onClick={() => {
                          document
                            .getElementById(
                              stlpec.datum + params.row.znacka.trim()
                            )
                            .focus();
                        }}
                      >
                        <div
                          style={{
                            width: 69,
                            height: 24,
                            padding: 4,
                            fontSize: 11,
                            textAlign: "end",
                            backgroundColor:
                              stlpec.farba_odoslane === undefined
                                ? "transparent"
                                : stlpec.farba_odoslane,
                            color:
                              stlpec.farba_odoslane !== undefined
                                ? "white"
                                : "black",
                          }}
                          title={stlpec.tooltip_odoslane}
                        >
                          {stlpec.odoslane}
                        </div>
                        <input
                          //className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                          title={stlpec.tooltip_objednane}
                          id={stlpec.datum + params.row.znacka}
                          name={stlpec.datum + params.row.znacka}
                          onMouseDown={preventSelection}
                          onMouseMove={preventSelection}
                          style={{
                            width: 69,
                            height: 24,
                            padding: 4,
                            fontSize: 11,
                            textAlign: "end",
                            backgroundColor:
                              stlpec.farba_objednane === undefined
                                ? "transparent"
                                : stlpec.farba_objednane,
                            color:
                              stlpec.bunky[0].mnozstvo === undefined
                                ? "black"
                                : "white",
                          }}
                          onFocus={handleFocus}
                          className={styles.cell_text}
                          type="number"
                          disabled={head_datum < today}
                          placeholder=""
                          onKeyDown={async (e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                              //setIsIn(true)
                              await document
                                .getElementById(
                                  stlpec.datum + params.row.znacka.trim()
                                )
                                .blur();
                            }
                          }}
                          onBlur={async (e) => {
                            console.log(stlpec.datum);
                            //blok na vydenie z bunky bez obnovi
                            if (
                              stlpec.bunky[0].mnozstvo === Number(e.target.value) ||
                              e.target.value === ""
                            ) {
                              return;
                            }
                            var text_stop_obj = "";
                            //ak je stop obj nastaví sa text pre stop obj
                            if (stlpec.stop_obj) {
                              text_stop_obj =
                                "Objednání suroviny (" +
                                stlpec.znacka +
                                ") je blokováno od " +
                                moment(
                                  new Date(params.row.stop_obj),
                                  "ddd MMM DD YYYY HH:mm:ss"
                                ).format("D.M.YYYY") +
                                ".";
                            }
                            //ak je stop obj a nahrada nieje vydem
                            if (stlpec.stop_obj && params.row.nahrada === "") {
                              swal("Upozornění!", text_stop_obj, "warning");
                              try {
                                document.getElementById(
                                  stlpec.datum + params.row.znacka.trim()
                                ).value =
                                  stlpec.bunky[0].mnozstvo !== undefined
                                    ? stlpec.bunky[0].mnozstvo
                                    : "";
                              } catch (err) {
                                //console.log(err)
                              }
                              return;
                            }
                            //ak je zadana nahrada idem po tejto vetve
                            if (params.row.nahrada !== "") {
                              await swal({
                                content: {
                                  element: "div",
                                  attributes: {
                                    innerHTML:
                                      text_stop_obj +
                                      "\n" +
                                      "Položka má náhradu " +
                                      params.row.nahrada +
                                      " - " +
                                      params.row.nahrada_nazov +
                                      "\n" +
                                      "Chcete přejít na tuto položku?",
                                  },
                                },
                                icon: "info",
                                buttons: ["Ne ", "Ano"],
                              }).then(async (willShow) => {
                                if (willShow) {
                                  //chcem sa nastavit na nahrada položku
                                  dispatch(
                                    search({
                                      data: {
                                        ...searchdata,
                                        searchObjednavky_typERP_O:
                                          params.row.nahrada,
                                      },
                                    })
                                  );
                                  try {
                                    document.getElementById(
                                      stlpec.datum + params.row.znacka.trim()
                                    ).value =
                                      stlpec.bunky[0].mnozstvo !== undefined
                                        ? stlpec.bunky[0].mnozstvo
                                        : "";
                                  } catch (err) {
                                    //console.log(err)
                                  }
                                } else {
                                  //zadavam na položku kde stojim

                                  //ak je stop_obj vraciam hodnotu  v inpute a ukoncujem
                                  // eslint-disable-next-line no-unreachable
                                  if (stlpec.stop_obj) {
                                    //swal("Upozornění!", text_stop_obj, "warning");
                                    try {
                                      document.getElementById(
                                        stlpec.datum + params.row.znacka.trim()
                                      ).value =
                                        stlpec.bunky[0].mnozstvo !== undefined
                                          ? stlpec.bunky[0].mnozstvo
                                          : "";
                                    } catch (err) {
                                      //console.log(err)
                                    }
                                    return;
                                  }
                                  //ak nueje stop_obj pokracujem na zapis hodnoty
                                  // dispatch(
                                  //   search({
                                  //     data: {
                                  //       ...searchdata,
                                  //       searchObjednavky_typERP_O: "",
                                  //     },
                                  //   })
                                  // );
                                  // filter(
                                  //   objednavky_typERP_Odata.allRows,
                                  //   null,
                                  //   "",
                                  //   pickerObjednavky_typERP_Odata
                                  // );
                                  if (allData.riadky !== undefined)
                                    createColumns(allData);
                                  if (
                                    stlpec.bunky[0].mnozstvo !== undefined &&
                                    e.target.value === ""
                                  ) {
                                    setIsActive(true);
                                    setLoaderText("Zápis do objednávek");
                                    zapis(
                                      head_datum,
                                      Number(0),
                                      params.row.znacka,
                                      apiRef.current.getScrollPosition(),
                                      stlpec.datum + params.row.znacka.trim(),
                                      data.hlavicka
                                    );
                                  }
                                  if (
                                    stlpec.bunky[0].mnozstvo !==
                                      Number(e.target.value) &&
                                    e.target.value !== ""
                                  ) {
                                    document.getElementById(
                                      stlpec.datum + params.row.znacka.trim()
                                    ).value = Number(e.target.value);
                                    nastav_balne_mn(
                                      Number(e.target.value),
                                      params.row.balne_mn,
                                      params.row.des_mista,
                                      params.row.mj,
                                      head_datum,
                                      params.row.znacka,
                                      apiRef.current.getScrollPosition(),
                                      stlpec.datum + params.row.znacka.trim(),
                                      data.hlavicka
                                    );
                                  }
                                }
                              });
                            }
                            //blok na ukoncenie ak je nahrada
                            if (params.row.nahrada !== "") {
                              return;
                            }
                            //pokracovanie zapisu
                            if (
                              stlpec.bunky[0].mnozstvo !== undefined &&
                              e.target.value === ""
                            ) {
                              setIsActive(true);
                              setLoaderText("Zápis do objednávek");
                              zapis(
                                head_datum,
                                Number(0),
                                params.row.znacka,
                                apiRef.current.getScrollPosition(),
                                stlpec.datum + params.row.znacka.trim(),
                                data.hlavicka
                              );
                            }
                            if (
                              stlpec.bunky[0].mnozstvo !== Number(e.target.value) &&
                              e.target.value !== ""
                            ) {
                              document.getElementById(
                                stlpec.datum + params.row.znacka.trim()
                              ).value = Number(e.target.value);

                              nastav_balne_mn(
                                Number(e.target.value),
                                params.row.balne_mn,
                                params.row.des_mista,
                                params.row.mj,
                                head_datum,
                                params.row.znacka,
                                apiRef.current.getScrollPosition(),
                                stlpec.datum + params.row.znacka.trim(),
                                data.hlavicka
                              );
                            }
                          }}
                          defaultValue={
                            stlpec.bunky[0].mnozstvo === undefined
                              ? ""
                              : stlpec.bunky[0].mnozstvo
                          }
                          //value={stlpec.bunky[0].mnozstvo}
                          onChange={(e) => {
                            // e.bubbles = false;
                            // e.defaultPrevented = true;
                            try {
                              var new_data = [...data.riadky];
                              var riadok = new_data.find(
                                (x) =>
                                  x.znacka.trim() === params.row.znacka.trim()
                              );
                              var bunka = riadok.stlpce.find(
                                (x) => x.datum === params.field
                              );
                              var new_bunka = {
                                ...bunky,
                                objednane: Number(e.target.value),
                              };
                              bunka = new_bunka;
                              //createColumns({ ...data, riadky: new_data });
                              if (
                                validateNumberWithMaxDecimals(
                                  Number(e.target.value),
                                  riadok.des_mista
                                )
                              ) {
                                dispatch(objednavky_typERP_OAllRows(new_data));
                                filter(
                                  new_data,
                                  null,
                                  searchdata.searchObjednavky_typERP_O,
                                  pickerObjednavky_typERP_Odata
                                );
                              } else {
                                document.getElementById(
                                  stlpec.datum + params.row.znacka.trim()
                                ).value = Number(e.target.value.slice(0, -1));
                              }
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        />
                      </div>
                    );
                  }
                })}
            </div> */}
            <div>
              {params.row.stlpce &&
                params.row.stlpce.map((stlpec, index) => {
                  if (stlpec.datum === head.datum) {
                    return (
                      <div
                        key={index}
                        className={
                          head_datum < today ? styles.cell_dis : styles.cell
                        }
                        onClick={() => {
                          try {
                            document
                              .getElementById(
                                stlpec.datum + params.row.znacka.trim()
                              )
                              .focus();
                          } catch (e) {}
                        }}
                      >
                        {stlpec.bunky.toReversed().map((bunka, index) => {
                          switch (bunka.typ) {
                            case "odoslane":
                              return (
                                <div
                                  style={{
                                    width: 69,
                                    height: stlpec.bunky.length === 1 ? 48 : 24,
                                    padding: 4,
                                    fontSize: 11,
                                    textAlign: "end",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "end",
                                    backgroundColor: bunka.farba,
                                    color:
                                      stlpec.farba_odoslane !== 0
                                        ? "white"
                                        : "black",
                                  }}
                                  title={bunka.tooltip}
                                >
                                  {bunka.mnozstvo}
                                </div>
                              );
                            case "objednane":
                              return (
                                <input
                                  //className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                  title={bunka.tooltip}
                                  id={stlpec.datum + params.row.znacka}
                                  name={stlpec.datum + params.row.znacka}
                                  onMouseDown={preventSelection}
                                  onMouseMove={preventSelection}
                                  style={{
                                    width: 69,
                                    height: index === 0 ? 48 : 24,
                                    padding: 4,
                                    fontSize: 11,
                                    textAlign: "end",
                                    backgroundColor: bunka.farba,
                                    color:
                                      bunka.mnozstvo === undefined
                                        ? "black"
                                        : "white",
                                  }}
                                  onFocus={handleFocus}
                                  className={styles.cell_text}
                                  type="number"
                                  disabled={bunka.disabled}
                                  placeholder=""
                                  onKeyDown={async (e) => {
                                    if (e.keyCode === 13) {
                                      e.preventDefault();
                                      //setIsIn(true)
                                      await document
                                        .getElementById(
                                          stlpec.datum +
                                            params.row.znacka.trim()
                                        )
                                        .blur();
                                    }
                                  }}
                                  onBlur={async (e) => {
                                    //console.log(stlpec.datum);
                                    //blok na vydenie z bunky bez obnovi
                                    if (
                                      bunka.mnozstvo === Number(e.target.value)
                                    ) {
                                      return;
                                    }
                                    if (
                                      e.target.value === "" &&
                                      bunka.farba === "transparent"
                                    ) {
                                      return;
                                    }
                                    var text_stop_obj = "";
                                    //ak je stop obj nastaví sa text pre stop obj
                                    if (stlpec.stop_obj) {
                                      text_stop_obj =
                                        "<div>Položka (" +
                                        stlpec.znacka +
                                        ") je nedostupná!</div>" +
                                        "<div>Její objednání je blokováno od&nbsp;" +
                                        moment(
                                          new Date(params.row.stop_obj),
                                          "ddd MMM DD YYYY HH:mm:ss"
                                        ).format("D.M.YYYY") +
                                        ".</div>";
                                    }
                                    //ak je stop obj a nahrada nieje vydem
                                    if (
                                      stlpec.stop_obj &&
                                      params.row.nahrada === ""
                                    ) {
                                      swal({
                                        content: {
                                          element: "div",
                                          attributes: {
                                            innerHTML: text_stop_obj,
                                          },
                                        },
                                        icon: "info",
                                        //title: "Upozornění!"
                                      });

                                      try {
                                        document.getElementById(
                                          stlpec.datum +
                                            params.row.znacka.trim()
                                        ).value =
                                          bunka.mnozstvo !== undefined
                                            ? bunka.mnozstvo
                                            : "";
                                      } catch (err) {
                                        //console.log(err)
                                      }
                                      return;
                                    }
                                    //ak je zadana nahrada idem po tejto vetve
                                    if (params.row.nahrada !== "") {
                                      await swal({
                                        content: {
                                          element: "div",
                                          attributes: {
                                            innerHTML:
                                              text_stop_obj +
                                              "\n" +
                                              "Položka má náhradu " +
                                              params.row.nahrada +
                                              " - " +
                                              params.row.nahrada_nazov +
                                              ".\n" +
                                              "Chcete přejít na tuto položku?",
                                          },
                                        },
                                        icon: "info",
                                        buttons: ["Ne ", "Ano"],
                                      }).then(async (willShow) => {
                                        if (willShow) {
                                          //chcem sa nastavit na nahrada položku
                                          dispatch(
                                            search({
                                              data: {
                                                ...searchdata,
                                                searchObjednavky_typERP_O:
                                                  params.row.nahrada,
                                              },
                                            })
                                          );
                                          try {
                                            document.getElementById(
                                              stlpec.datum +
                                                params.row.znacka.trim()
                                            ).value =
                                              bunka.mnozstvo !== undefined
                                                ? bunka.mnozstvo
                                                : "";
                                          } catch (err) {
                                            //console.log(err)
                                          }
                                        } else {
                                          //zadavam na položku kde stojim

                                          //ak je stop_obj vraciam hodnotu  v inpute a ukoncujem
                                          // eslint-disable-next-line no-unreachable
                                          if (stlpec.stop_obj) {
                                            //swal("Upozornění!", text_stop_obj, "warning");
                                            try {
                                              document.getElementById(
                                                stlpec.datum +
                                                  params.row.znacka.trim()
                                              ).value =
                                                bunka.mnozstvo !== undefined
                                                  ? bunka.mnozstvo
                                                  : "";
                                            } catch (err) {
                                              //console.log(err)
                                            }
                                            return;
                                          }
                                          //ak nueje stop_obj pokracujem na zapis hodnoty
                                          // dispatch(
                                          //   search({
                                          //     data: {
                                          //       ...searchdata,
                                          //       searchObjednavky_typERP_O: "",
                                          //     },
                                          //   })
                                          // );
                                          // filter(
                                          //   objednavky_typERP_Odata.allRows,
                                          //   null,
                                          //   "",
                                          //   pickerObjednavky_typERP_Odata
                                          // );
                                          if (allData.riadky !== undefined)
                                            createColumns(allData);
                                          if (
                                            bunka.mnozstvo !== undefined &&
                                            e.target.value === ""
                                          ) {
                                            setIsActive(true);
                                            setLoaderText(
                                              "Zápis do objednávek"
                                            );
                                            zapis(
                                              head_datum,
                                              Number(0),
                                              params.row.znacka,
                                              apiRef.current.getScrollPosition(),
                                              stlpec.datum +
                                                params.row.znacka.trim(),
                                              data.hlavicka
                                            );
                                          }
                                          if (
                                            bunka.mnozstvo !==
                                              Number(e.target.value) &&
                                            e.target.value !== ""
                                          ) {
                                            document.getElementById(
                                              stlpec.datum +
                                                params.row.znacka.trim()
                                            ).value = Number(e.target.value);
                                            nastav_balne_mn(
                                              Number(e.target.value),
                                              params.row.balne_mn,
                                              params.row.des_mista,
                                              params.row.mj,
                                              head_datum,
                                              params.row.znacka,
                                              apiRef.current.getScrollPosition(),
                                              stlpec.datum +
                                                params.row.znacka.trim(),
                                              data.hlavicka
                                            );
                                          }
                                        }
                                      });
                                    }
                                    //blok na ukoncenie ak je nahrada
                                    if (params.row.nahrada !== "") {
                                      return;
                                    }
                                    //pokracovanie zapisu
                                    if (
                                      bunka.mnozstvo !== undefined &&
                                      e.target.value === ""
                                    ) {
                                      setIsActive(true);
                                      setLoaderText("Zápis do objednávek");
                                      zapis(
                                        head_datum,
                                        Number(0),
                                        params.row.znacka,
                                        apiRef.current.getScrollPosition(),
                                        stlpec.datum + params.row.znacka.trim(),
                                        data.hlavicka
                                      );
                                    }
                                    if (
                                      bunka.mnozstvo !==
                                        Number(e.target.value) &&
                                      e.target.value !== ""
                                    ) {
                                      document.getElementById(
                                        stlpec.datum + params.row.znacka.trim()
                                      ).value = Number(e.target.value);

                                      nastav_balne_mn(
                                        Number(e.target.value),
                                        params.row.balne_mn,
                                        params.row.des_mista,
                                        params.row.mj,
                                        head_datum,
                                        params.row.znacka,
                                        apiRef.current.getScrollPosition(),
                                        stlpec.datum + params.row.znacka.trim(),
                                        data.hlavicka
                                      );
                                    }
                                  }}
                                  defaultValue={
                                    bunka.mnozstvo === undefined
                                      ? ""
                                      : bunka.mnozstvo
                                  }
                                  //value={stlpec.bunky[0].mnozstvo}
                                  onChange={(e) => {
                                    // e.bubbles = false;
                                    // e.defaultPrevented = true;
                                    console.log(e);
                                    try {
                                      var new_data = [...data.riadky];
                                      var riadok = new_data.find(
                                        (x) =>
                                          x.znacka.trim() ===
                                          params.row.znacka.trim()
                                      );
                                      var bunka = riadok.stlpce.find(
                                        (x) => x.datum === params.field
                                      );
                                      var new_bunka = {
                                        ...bunka,
                                        objednane: Number(e.target.value),
                                      };
                                      // if(e.target.value == ""){
                                      //   document.getElementById(
                                      //     stlpec.datum +
                                      //       params.row.znacka.trim()
                                      //   ).value = bunka.objednane;
                                      //   return;
                                      // }
                                      bunka = new_bunka;
                                      //createColumns({ ...data, riadky: new_data });
                                      if (
                                        validateNumberWithMaxDecimals(
                                          Number(e.target.value),
                                          riadok.des_mista
                                        )
                                      ) {
                                        dispatch(
                                          objednavky_typERP_OAllRows(new_data)
                                        );
                                        filter(
                                          new_data,
                                          null,
                                          searchdata.searchObjednavky_typERP_O,
                                          pickerObjednavky_typERP_Odata
                                        );
                                      } else {
                                        document.getElementById(
                                          stlpec.datum +
                                            params.row.znacka.trim()
                                        ).value = Number(
                                          e.target.value.slice(0, -1)
                                        );
                                      }
                                    } catch (error) {
                                      console.log(error);
                                    }
                                  }}
                                />
                              );
                          }
                        })}
                      </div>
                    );
                  }
                })}
            </div>
          </>
        ),
      });
    });
    //console.log(newColumns);
    setColumns(newColumns);
  }

  LicenseInfo.setLicenseKey(getLicensePremium());
  return (
    <LoadingOverlay active={isActive} spinner text={loaderText}>
      <Card bezPaddingu={true}>
        {loading ? (
          <ContentLoader
            speed={2}
            width={"100%"}
            height={window.innerHeight - 170}
            backgroundColor={darkMode.value ? "#1e2730" : "#f3f3f3"}
            foregroundColor={darkMode.value ? "#2d3641" : "#ecebeb"}
          >
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
              (i) =>
                [0, 1, 2, 3, 4, 5, 6].map((j) => (
                  <rect
                    key={j}
                    x={0}
                    y={2 + i * (window.innerHeight / 20)}
                    rx="3"
                    ry="3"
                    width={"100%"}
                    height={40}
                  />
                ))
            )}
          </ContentLoader>
        ) : (
          <div className={styles.Sklad}>
            {window.innerWidth < 600 ? (
              <div
                style={{ height: window.innerHeight - 243 }}
                className={styles.mobile}
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-device-mobile-rotated"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    height="5em"
                    width="5em"
                  >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <rect x="3" y="6" width="18" height="12" rx="2" />
                    <path d="M20 11v2M7 12h-.01" />
                  </svg>
                </div>

                <div>Otočte si telefon na šířku.</div>
              </div>
            ) : (
              <div>
                <div className={styles.cardHead}>
                  <div className={styles.search_kosik}>
                    <div className={styles.search}>
                      <Search field={"searchObjednavky_typERP_O"}></Search>
                    </div>
                    <div
                      className={
                        isKosikEmpty() ? styles.kosik_ico_dis : styles.kosik_ico
                      }
                      onClick={async () => {
                        if (!isKosikEmpty()) {
                          setLayoutActive(true);
                          setVisible_kosik(true);
                          setLayoutText("Načítám");
                          await nacitaj_kosik();
                        }
                      }}
                    >
                      <i class="fa fa-shopping-cart"></i>
                    </div>
                  </div>
                  <div className={styles.dd}>
                    <div className={styles.dditem}>
                      <div className={styles.ddtitle}>Skupina: </div>

                      <Dropdown
                        title=""
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        options={options}
                      />
                    </div>
                    <div className={styles.dditem}>
                      <div className={styles.ddtitle}>Tagy: </div>

                      <DDTagy
                        title=""
                        className={styles.icon}
                        classDropdownHead={styles.dropdownHead}
                        options={pickerTagyObjednavky_typERP_Odata.tagy}
                        value={value}
                        setValue={setValue}
                      />
                    </div>
                  </div>
                </div>

                <br />
                <div className={styles.zoomout}>
                  <ThemeProvider theme={theme}>
                    <DataGridPremium
                      apiRef={apiRef}
                      sx={styling}
                      localeText={
                        csCZ.components.MuiDataGrid.defaultProps.localeText
                      }
                      {...{
                        rows: objednavky_typERP_Odata.rows,
                        columns: columns,
                      }}
                      disableRowSelectionOnClick
                      disableAutoFocus
                      disableColumnMenu
                      defaultGroupingExpansionDepth={-1}
                      hideFooter
                      rowHeight={50}
                      columnHeaderHeight={50}
                      rowGroupingModel={rowGroupingModel}
                      onRowGroupingModelChange={(model) =>
                        setRowGroupingModel(model)
                      }
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            skupina: false,
                            nazov: false,
                          },
                        },
                        pinnedColumns: {
                          left: ["__row_group_by_columns_group__"],
                        },
                      }}
                      groupingColDef={{
                        leafField: "nazov",
                        headerClassName: "weekend6_help_nadpis",
                        cellClassName: "weekend6_help_cell",
                        renderHeader: () => (
                          <div className={styles.nazov}>
                            <div className={styles.nazovTitle}>Položky</div>
                            <div className={styles.pokladna}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                height="1.15em"
                                width="1.15em"
                              >
                                <path d="M288 0c17.7 0 32 14.33 32 32v64c0 17.7-14.3 32-32 32h-80v32h216.1c32.5 0 59.4 23.1 64.1 54.4l22.7 149.7c.7 4.7 1.1 9.5 1.1 14.3V448c0 35.3-28.7 64-64 64H64c-35.35 0-64-28.7-64-64v-69.6c0-4.8.362-9.6 1.083-14.3L23.76 214.4C28.5 183.1 55.39 160 87.03 160h56.07v-32h-80c-16.77 0-32-14.3-32-32V32c0-17.67 15.23-32 32-32H288zM96 48c-8.84 0-16 7.16-16 16s7.16 16 16 16h160c8.8 0 16-7.16 16-16s-7.2-16-16-16H96zM80 448h352c8.8 0 16-7.2 16-16s-7.2-16-16-16H80c-8.84 0-16 7.2-16 16s7.16 16 16 16zm32-232c-13.25 0-24 10.7-24 24s10.75 24 24 24c13.3 0 24-10.7 24-24s-10.7-24-24-24zm96 48c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24zm-48 32c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm144-32c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24zm-48 32c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm144-32c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24zm-48 32c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24z" />
                              </svg>
                            </div>
                          </div>
                        ),
                      }}
                    />
                  </ThemeProvider>
                </div>
              </div>
            )}
          </div>
        )}
      </Card>
      <ModalKosik
        nacitaj_kosik={nacitaj_kosik}
        visible={visible_kosik}
        setVisible={setVisible_kosik}
        data={kosikData}
        buttony={kosikButtony}
        objednat_data={objednat_data}
        objednat={objednat}
        setIsActive={setIsActive}
        setLoaderText={setLoaderText}
        hlavicka={hlavicka}
        datum_active={datum_active}
        setDatum_active={setDatum_active}
        vynulovat_kosik={vynulovat_kosik}
        aktualizuj_kosik={aktualizuj_kosik}
        layoutText={layoutText}
        setLayoutText={setLayoutText}
        layoutActive={layoutActive}
        setLayoutActive={setLayoutActive}
        zapis={nastav_balne_mn}
        zapis_={zapis}
        dataObjednavky={objednavky_typERP_Odata.allRows}
        typ_objednavky={"O"}
        stop_obj_znacky={stop_obj_znacky}
        kosikZmena={kosikZmena}
      />
      <ModalKopiaObjednavky
        nacitaj_kopie={nacitaj_kopie}
        visible={visible_kopia}
        setVisible={setVisible_kopia}
        data={kopieData}
        buttony={kopieButtony}
        objednat_data={objednat_data}
        //objednat={objednat}
        setIsActive={setIsActive}
        setLoaderText={setLoaderText}
        hlavicka={hlavicka}
        vynulovat_kosik={vynulovat_kosik}
        aktualizuj_kosik={aktualizuj_kosik}
        layoutText={layoutText_kop}
        setLayoutText={setLayoutText_kop}
        layoutActive={layoutActive_kop}
        setLayoutActive={setLayoutActive_kop}
        datum_kopie={datum_kopie}
        setDatum_kopie={setDatum_kopie}
        zapis_kopie={zapis_kopie}
        cis_objed_kopia={cis_objed_kopia}
        setCis_objed_kopia={setCis_objed_kopia}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
        dataObjednavky={objednavky_typERP_Odata.allRows}
        typ_objednavky={"O"}
      />
      <ModalPresunPolozekNaInyDen
        nacitaj_data={nacitaj_plozky_na_presun}
        visible={visible_presun}
        setVisible={setVisible_presun}
        data={presunData}
        objednat_data={objednat_data}
        //objednat={objednat}
        setIsActive={setIsActive}
        setLoaderText={setLoaderText}
        hlavicka={hlavicka}
        vynulovat_kosik={vynulovat_kosik}
        aktualizuj_kosik={aktualizuj_kosik}
        layoutText={layoutText_presun}
        setLayoutText={setLayoutText_presun}
        layoutActive={layoutActive_presun}
        setLayoutActive={setLayoutActive_presun}
        datum_presun={datum_presun}
        setDatum_presun={setDatum_presun}
        zapis_presun={zapis_presun}
        rowSelectionModel={rowSelectionModel_presun}
        setRowSelectionModel={setRowSelectionModel_presun}
        dataObjednavky={objednavky_typERP_Odata.allRows}
        buttony={presunButtony}
        nacitaj_plozky_na_presun={nacitaj_plozky_na_presun}
        typ_objednavky={"O"}
      />
    </LoadingOverlay>
  );
};

export default Objednavky_typERP_O;
